import './index.scss';
import { ChangeEvent, DragEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { Paper, CircularProgress } from '@mui/material';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as utils from '../utils';
import { isValidEmail } from '../utils';
import useAuth from '../../../resources/auth/auth-hook';
import JoditEditor from 'jodit-react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import DropdownBtn, { Item } from '../../../components/ui/dropdown-btn';
import useViolationRule from '../../../resources/violation-rule/violation-rule-hook';
import { stringTimestamp, findIssueStartTime } from '../utils';
import parse from 'html-react-parser';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { MdOutlineFileCopy, MdOutlinePictureAsPdf, MdRefresh } from 'react-icons/md';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { CgDanger, CgCheckO } from 'react-icons/cg';
import reviewSheetService from '../../../resources/review-sheet/review-sheet.service';
import { pushReview, setEnableReview } from '../../../redux/reducers/submissionReducer';
import { Review, ReviewDataDTO } from '../../../interface/review';
import { getLastReview, hasTheReviewBeenSent } from '../../../helpers/reviews-helper';
import LoadingScreen from '../../loading';
import { SubmissionDetailsDTO } from '../../../interface/submission-details-dto';
import { ReviewIssueViolationRule } from '../../../interface/review-issue-violation-rule';
import { SubmissionIssueDTO } from '../../../interface/submission-issue-dto';
import * as transcriptReducer from '../../../redux/reducers/transcriptReducer';
import * as reviewReducer from '../../../redux/reducers/reviewReducer';
import { ISegment } from '../../../interface/segment';
import { ReviewSheetTemplate } from '../../../interface/review-sheet-template';
import { Tab } from '../../../interface/tab';
import { getMimeType, isAudioOrVideoMediaType } from '../../../helpers/submission-helper';
import { Tooltip } from 'react-tooltip';
import { Attachment } from '../../../interface/attachment';
import { FileExtended } from '../../../interface/file-extended';
import { FileStatus } from '../../../enum/file-status';
import attachmentService from '../../../resources/attachments/attachment.service';
import { ReviewSteps } from '../../../enum/review-steps';
import { AttachmentDTO } from '../../../interface/attachment-dto';
import { FaFileUpload, FaRegTrashAlt } from 'react-icons/fa';
import { selectFileIcon } from '../attachment-tab';
import { formatBytes } from '../../../helpers/file-helper';
import { GoDotFill } from 'react-icons/go';
import AlertComponent from '../../../components/ui/alert';
import { formatDate } from '../../../helpers/date-helper';
import { FiInfo } from 'react-icons/fi';
import { AxiosError } from 'axios';

const NON_AUDIO_OR_VIDEO_TEMPLATE = 'Review Template (non-audio/video)';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 4px)',
    right: 'calc(50% + 4px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#3478f6',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#3478f6',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#3478f6',
  }),
  '& .QontoStepIcon-completedIcon': {
    width: 12,
    height: 12,
    borderRadius: '50%',
    border: '2px solid #3478f6',
    zIndex: '99',
  },
  '& .QontoStepIcon-circle': {
    width: 12,
    height: 12,
    borderRadius: '50%',
    border: '2px solid #eaeaf0',
    zIndex: '99',
  },
}));

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <div className="QontoStepIcon-completedIcon" /> : <div className="QontoStepIcon-circle" />}
    </QontoStepIconRoot>
  );
}

interface ReviewStep {
  id: string;
  label: string;
}

let defaultSteps: ReviewStep[] = [
  { id: ReviewSteps.SELECT_TEMPLATE, label: 'Select Template' },
  { id: ReviewSteps.FILL_INFORMATION, label: 'Fill in Information' },
  { id: ReviewSteps.PREVIEW_AND_SUBMIT, label: 'Preview & Submit' },
];

interface FeedbackMessage {
  positive?: string | ReactNode;
  negative?: string | ReactNode;
}

export default function ReviewTab() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const currentRecord: SubmissionDetailsDTO = useAppSelector(state => state.submission.currentRecord);
  const { enableReview } = useAppSelector(state => state.submission);
  const { violationRules, fetchAllViolationRules } = useViolationRule();
  const [selectedTemplate, setSelectedTemplate] = useState<ReviewSheetTemplate>();
  const [isValidEmailState, setValidEmailState] = useState<boolean>(true);
  const [isValidEmailCcState, setValidEmailCcState] = useState<boolean>(true);
  const [showApLegalEntityName, setShowApLegalEntityName] = useState(false);
  const [showApplyCode, setShowApplyCode] = useState(false);
  const [showBsmStatus, setShowBsmStatus] = useState(false);
  const [showTitleEnglish, setShowTitleEnglish] = useState(false);
  const [showSignature, setShowSignature] = useState(false);
  const [showSpeakerFullName, setShowSpeakerFullName] = useState(false);
  const [steps, setSteps] = useState(defaultSteps);
  const [activeStep, setActiveStep] = useState<string>(ReviewSteps.SELECT_TEMPLATE);
  const [apLegalEntityName, setApLegalEntityName] = useState(
    currentRecord.approvedProviderName || currentRecord.submitterName,
  );
  const [apContactName, setApContactName] = useState(currentRecord.approvedProviderName || '');
  const [applyCode, setApplyCode] = useState(currentRecord.applyCode || '');
  const [bsmStatus, setBsmStatus] = useState(currentRecord.workflowStatus || '');
  const [englishTitle, setTitleEnglish] = useState(currentRecord.englishTitle || '');
  const [signature, setSignature] = useState(user?.name || '');
  const [speaker, setSpeaker] = useState(currentRecord.speaker || '');
  const [attachments, setAttachments] = useState<FileExtended[]>([]);
  const [email, setEmail] = useState('');
  const [emailCc, setEmailCc] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState<FeedbackMessage>();
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [content, setContent] = useState('');
  const [defaultContent, setDefaultContent] = useState('');
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [attachErrorMessage, setAttachErrorMessage] = useState<string>('');
  const [previewAndSubmitErrorMessage, setPreviewAndSubmitErrorMessage] = useState<string>('');
  const editor = useRef(null);
  const submissionReviews: SubmissionIssueDTO[] = useAppSelector(state => state.submission.submissionReviews);
  const reviews: Review[] = useAppSelector(state => state.submission.reviews);
  const { hasChanges, proceedAction } = useAppSelector(state => state.review);
  const mergedSegments: ISegment[] = useAppSelector(state => state.transcript.mergedSegments);
  const templates: ReviewSheetTemplate[] = useAppSelector(state => state.review.templates);
  const lastReview = getLastReview(reviews);
  const dispatch = useAppDispatch();
  const prevAttachmentsRef = useRef<FileExtended[] | undefined>(undefined);

  const defaultSubject = `${currentRecord.applyCode} - ${currentRecord.englishTitle} - ${
    currentRecord.approvedProviderName ? `${currentRecord.approvedProviderName} -` : ''
  } ${formatDate(currentRecord.createdAt, 'MM.DD.YY')}`;
  const [subject, setSubject] = useState<string>(defaultSubject);
  const [timestampString, setTimestampString] = useState(stringTimestamp(mergedSegments || null));
  const currentTab: Tab = useAppSelector(state => state.submission.currentTab);

  // Fetch violation rules just once
  useEffect(() => {
    if (!violationRules.data && currentRecord) {
      fetchAllViolationRules();

      if (!mergedSegments || mergedSegments.length === 0) {
        processSegments();
      }

      setTimestampString(stringTimestamp(mergedSegments));
    }
    if (templates.length === 0) {
      fetchReviewSheetTemplates();
    }

    handleAttachmentStep();
  }, []);

  useEffect(() => {
    replaceTemplatePlaceholders(selectedTemplate);
  }, [bsmStatus, apLegalEntityName, apContactName, applyCode, bsmStatus, englishTitle, speaker, signature]);

  useEffect(() => {
    setDefaultTemplate(templates);
  }, [templates, currentTab]);

  useEffect(() => {
    if (email) setValidEmailState(isValidEmail(email));
  }, [email]);

  useEffect(() => {
    if (emailCc) setValidEmailCcState(isValidEmail(emailCc));
  }, [emailCc]);

  useEffect(() => {
    handleAttachmentStep();

    if (activeStep === ReviewSteps.FILL_INFORMATION && selectedTemplate) {
      // Step 2, show/hide the necessary input fields
      setShowApLegalEntityName(selectedTemplate.content.includes('$ap_legal_entity_name'));
      setShowApplyCode(selectedTemplate.content.includes('$apply_code'));
      setShowBsmStatus(selectedTemplate.content.includes('$status'));
      setShowTitleEnglish(selectedTemplate.content.includes('$title'));
      setShowSignature(selectedTemplate.content.includes('$signature'));
      setShowSpeakerFullName(selectedTemplate.content.includes('$speakers'));
    } else if ((activeStep === ReviewSteps.PREVIEW_AND_SUBMIT && selectedTemplate && !content) || proceedAction) {
      // Step 3, replace the placeholders on the selected template
      replaceTemplatePlaceholders(selectedTemplate);
    }
  }, [activeStep, selectedTemplate, proceedAction]);

  useEffect(() => {
    if (prevAttachmentsRef.current?.length !== attachments.length) {
      uploadFiles(attachments);
      prevAttachmentsRef.current = attachments;
    }
  }, [attachments]);

  useEffect(() => {
    if (user) {
      setSignature(user.name);
    }
  }, [user]);

  /**
   * Function that replaces the placehoders on the current selected template
   * @param selectedTemplate
   */
  function replaceTemplatePlaceholders(selectedTemplate: any) {
    const formattedCurrentDate = moment().format('MMMM Do, YYYY');
    let ruleViolations = '';

    if (!selectedTemplate) {
      return;
    }

    if (
      isAudioOrVideoMediaType(currentRecord.sourceLocation) &&
      selectedTemplate.content.includes('$rule_violations')
    ) {
      ruleViolations = buildRuleViolationsSection();
    } else if (selectedTemplate.content.includes('$rule_violations')) {
      ruleViolations = buildRuleViolationsSectionForNonAudioOrVideo();
    }

    const updatedContent =
      selectedTemplate.content
        .replaceAll('$date', formattedCurrentDate)
        .replaceAll('$ap_legal_entity_name', apLegalEntityName)
        .replaceAll('$ap_contact_name', apContactName)
        .replaceAll('$apply_code', applyCode)
        .replaceAll('$status', bsmStatus)
        .replaceAll('$title', englishTitle)
        .replaceAll('$speakers', speaker)
        .replaceAll('$signature', signature)
        .replaceAll('$rule_violations', ruleViolations) || '';

    dispatch(reviewReducer.setProceedAction(false));
    dispatch(reviewReducer.setHasChanges(false));

    setDefaultContent(updatedContent);
    setContent(updatedContent);
  }

  useEffect(() => {
    if (currentTab.name === 'Review' && !hasChanges) {
      replaceTemplatePlaceholders(selectedTemplate);
    }
  }, [currentTab, selectedTemplate]);

  function handleAttachmentStep() {
    if (selectedTemplate?.name === NON_AUDIO_OR_VIDEO_TEMPLATE) {
      addAttachmentsStep();
    } else {
      removeAttachmentsStep();
    }
  }

  function addAttachmentsStep() {
    if (!steps.some((step: ReviewStep) => step.id === ReviewSteps.ATTACH_FILE)) {
      const stepsCopy = [...steps];
      const newStep: ReviewStep = { id: ReviewSteps.ATTACH_FILE, label: 'Attach File' };
      stepsCopy.splice(2, 0, newStep);

      setSteps(stepsCopy);
    }
  }

  function removeAttachmentsStep() {
    const stepsCopy = [...steps];
    const index = steps.findIndex(step => step.id === ReviewSteps.ATTACH_FILE);

    if (index !== -1) {
      stepsCopy.splice(index, 1);
      setSteps(stepsCopy);
    }
  }

  function processSegments() {
    let segments = currentRecord.segments;

    if (typeof segments === 'string') {
      segments = JSON.parse(segments);
    }

    const merge = utils.mergeSegmentsAndTranscript(segments || []);
    dispatch(transcriptReducer.setMergedTranscript(merge.transcript));
    dispatch(transcriptReducer.setMergedSegments(merge.mergedSegments));
  }

  function fetchReviewSheetTemplates() {
    setIsLoading(true);

    return reviewSheetService
      .getTemplates()
      .promise.then((templates: ReviewSheetTemplate[]) => {
        dispatch(reviewReducer.setTemplates(templates));
      })
      .catch((err: any) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function setDefaultTemplate(templates: ReviewSheetTemplate[]) {
    // Check for the default template 'Reviews Template'
    const reviewTemplate = templates.find(template => template.name === 'Reviews Template');
    const reviewTemplateOtherMedia = templates.find(template => template.name === 'Review Template (non-audio/video)');
    let defaultTemplate = isAudioOrVideoMediaType(currentRecord.sourceLocation)
      ? reviewTemplate
      : reviewTemplateOtherMedia;

    // Check if any submission review has the user review status as true
    const hasNoIssues = submissionReviews?.every(
      (submissionReview: SubmissionIssueDTO) =>
        !submissionReview.reviewIssueViolationRule.some(issue => issue.userReviewStatus),
    );

    // If no issues, set the 'Authorization' template as default
    if (hasNoIssues) {
      defaultTemplate = templates.find(template => template.name === 'Authorization');
    }

    setSelectedTemplate(defaultTemplate);
  }

  function buildRuleViolationsSectionForNonAudioOrVideo(): string {
    let issuesText = '';

    submissionReviews?.forEach((sr: SubmissionIssueDTO) => {
      sr.reviewIssueViolationRule.forEach((rivr: ReviewIssueViolationRule) => {
        const ruleViolation = violationRules?.data?.find(rule => rule.id === rivr.violationRuleId);

        if (rivr.userReviewStatus === false) {
          return;
        }

        if (ruleViolation?.errorDescription || ruleViolation?.name) {
          issuesText +=
            '<div><span style="color: red;">' +
            (ruleViolation.errorDescription ? ruleViolation.errorDescription : ruleViolation.name) +
            '</span><br>';
        }

        if (rivr.description) {
          issuesText += `<strong>Comment: ${rivr.description} </strong></div><br>`;
        }
      });
    });

    return issuesText;
  }

  function buildRuleViolationsSection() {
    type IssueSection = {
      timeStart: number;
      finalText: string;
    };

    if (!currentRecord.transcript) {
      return '';
    }

    const issuesSection: IssueSection[] = [];
    const filteredSubmissionReviews = filterByUserReviewStatus(submissionReviews || []);
    const submissionReviewsGroupedByIssueContext = groupSubmissionReviewsByContext(filteredSubmissionReviews);
    submissionReviewsGroupedByIssueContext?.forEach(sr => {
      const timeStart = sr.reviewIssue.timeStart
        ? sr.reviewIssue.timeStart
        : findIssueStartTime(timestampString, currentRecord.transcript || '', sr.reviewIssue.issueContent)!.startTime;

      let issues = `<br>${moment.utc(timeStart * 1000).format('mm:ss')} - `;

      issues += processIssueContent(sr);

      // Adds the description section
      let finalText = '<div><br><span style="color: red;"> ';
      const descriptions = new Set();

      sr.reviewIssueViolationRule.forEach((rivr: ReviewIssueViolationRule) => {
        const ruleViolation = violationRules?.data?.find(rule => rule.id === rivr.violationRuleId);

        descriptions.add(ruleViolation?.errorDescription || ruleViolation?.name);
      });

      Array.from(descriptions).forEach((description, i) => {
        // error description
        finalText += `${description}`;

        if (i !== descriptions.size - 1) {
          finalText += '<br>';
        }
      });

      finalText += '</span>';

      // description
      finalText += issues;

      // comments
      const amountOfComments: number = sr.reviewIssueViolationRule.filter(
        (rivr: ReviewIssueViolationRule) => rivr.description,
      ).length;

      if (amountOfComments === 1) {
        finalText += '<br><strong>Comment: ';
      } else if (amountOfComments > 1) {
        finalText += '<br><strong>Comments: ';
      }

      sr.reviewIssueViolationRule.forEach((rivr: ReviewIssueViolationRule) => {
        if (rivr.description) {
          if (amountOfComments === 1) {
            finalText += `${rivr.description.replaceAll('double_quotes', '"')}`;
          } else {
            finalText += `${rivr.description.replaceAll('double_quotes', '"')} <br>`;
          }
        }
      });

      if (amountOfComments > 0) {
        finalText += '</strong>';
      }

      finalText += '</div>';

      issuesSection.push({
        timeStart,
        finalText: finalText,
      });
    });

    // Sort issuesSection based on timeStart
    issuesSection.sort((a, b) => (a.timeStart > b.timeStart ? 1 : -1));

    // Join each finalText in sorted issuesSection and return it
    const fullText = issuesSection.map(section => section.finalText).join('') + '<br>';

    return fullText;
  }

  function filterByUserReviewStatus(submissionReviews: SubmissionIssueDTO[]): SubmissionIssueDTO[] {
    const filteredSubmissionReviews: SubmissionIssueDTO[] = [];

    submissionReviews?.forEach(sr => {
      const issues = sr.reviewIssueViolationRule.filter(
        (rivr: ReviewIssueViolationRule) => rivr.userReviewStatus !== false,
      );

      if (issues.length > 0) {
        filteredSubmissionReviews.push({ ...sr, reviewIssueViolationRule: issues });
      }
    });

    return filteredSubmissionReviews;
  }

  function groupSubmissionReviewsByContext(submissionReviews: SubmissionIssueDTO[]): SubmissionIssueDTO[] {
    return submissionReviews?.reduce((acc: SubmissionIssueDTO[], obj) => {
      const existingItemIndex = acc.findIndex(
        item =>
          obj.reviewIssue.issueContext &&
          item.reviewIssue.issueContext &&
          item.reviewIssue.issueContext.trim() === obj.reviewIssue.issueContext.trim(),
      );
      const existingTimeStart = acc.find(item => item.reviewIssue.issueContext === obj.reviewIssue.issueContext)
        ?.reviewIssue.timeStart;

      if (existingItemIndex !== -1 && obj.reviewIssue.issueContext !== null) {
        const accIssueContent = acc[existingItemIndex].reviewIssue.issueContent;
        const accIssues = acc[existingItemIndex].reviewIssue.issues;
        const objIssueContent = obj.reviewIssue.issueContent;
        const objIssues = obj.reviewIssue.issues;

        if (accIssueContent !== '') {
          acc[existingItemIndex].reviewIssue.issueContent = `${accIssueContent}|||${objIssueContent}`;
        }
        acc[existingItemIndex].reviewIssueViolationRule.push(...obj.reviewIssueViolationRule);
        acc[existingItemIndex].reviewIssue.issues = accIssues.concat(objIssues);

        if (
          existingTimeStart !== undefined &&
          obj.reviewIssue.timeStart !== undefined &&
          obj.reviewIssue.timeStart < existingTimeStart
        ) {
          acc[existingItemIndex].reviewIssue.timeStart = obj.reviewIssue.timeStart;
          acc[existingItemIndex].reviewIssue.timeEnd = obj.reviewIssue.timeEnd;
        }
      } else {
        acc.push({
          reviewIssue: { ...obj.reviewIssue },
          reviewIssueViolationRule: [...obj.reviewIssueViolationRule],
        });
      }
      return acc;
    }, []);
  }

  function processIssueContent(sr: any): string {
    let issues = '';

    if (sr.reviewIssue.issueContext) {
      if (sr.reviewIssue.issueContext.includes("className='description'")) {
        issues += sr.reviewIssue.issueContext.replace('<span', `<span style="background: #FFFF00;"`);
      } else {
        const splitedContent = sr.reviewIssue.issueContent.split('|||');

        if (splitedContent.length > 1) {
          let newIssueContext = sr.reviewIssue.issueContext;
          splitedContent.forEach((content: string) => {
            if (content) {
              newIssueContext = utils.replaceContextWithHighlight(newIssueContext, content);
            }
          });
          issues += newIssueContext;
        } else {
          issues += utils.processHighlightedIssues(sr);
        }
      }
    } else {
      issues += utils.highlightWithYellowBackground(sr.reviewIssue.issueContent);
    }

    return issues;
  }

  // Function to check if any required fields are empty
  const isNextButtonDisabled = () => {
    return (
      selectedTemplate === null ||
      (activeStep === ReviewSteps.ATTACH_FILE &&
        attachments.some((attachment: FileExtended) => attachment.status !== FileStatus.UPLOADED)) ||
      (activeStep === ReviewSteps.FILL_INFORMATION &&
        (!apLegalEntityName || !applyCode || !bsmStatus || !englishTitle || !signature || !email || !isValidEmailState))
    );
  };

  const handleTemplateClick = (template: ReviewSheetTemplate | Item | null | undefined) => {
    if (!template) {
      return;
    }

    const templateFound = templates.find(
      (currentTemplate: ReviewSheetTemplate) => currentTemplate.id.toString() === template.id.toString(),
    );

    setSelectedTemplate(templateFound);
  };

  function handleCopy() {
    const textContent = document.querySelector('.jodit-wysiwyg')?.textContent;
    navigator.clipboard.writeText(textContent || '');
  }

  function handlePdf() {
    const element = document.querySelector('.jodit-wysiwyg') as HTMLElement;

    if (element) {
      html2canvas(element).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4'); // portrait, millimeters, A4 size
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save('review-sheet');
      });
    }
  }

  const handleBack = () => {
    const currentStepIndex = steps.findIndex((step: ReviewStep) => step.id === activeStep);

    if (currentStepIndex > 0) {
      setActiveStep(steps[currentStepIndex - 1].id);
    } else {
      setActiveStep(steps[0].id);
    }
  };

  const handleNext = () => {
    const currentStepIndex = steps.findIndex((step: ReviewStep) => step.id === activeStep);
    const lastStepIndex = steps.length - 1;

    if (currentStepIndex < lastStepIndex) {
      setActiveStep(steps[currentStepIndex + 1].id);
    } else {
      setActiveStep(steps[lastStepIndex].id);
    }
  };

  function handleModal(mustCheckAttachment = true) {
    setPreviewAndSubmitErrorMessage('');

    if (mustCheckAttachment && selectedTemplate?.name === NON_AUDIO_OR_VIDEO_TEMPLATE && attachments.length === 0) {
      setShowAttachmentModal(true);
      return;
    }

    if (subject.trim().length === 0) {
      setPreviewAndSubmitErrorMessage('Subject cannot be empty. Please provide a subject for your message.');
      return;
    }

    const formData = new FormData();
    formData.append('submissionId', currentRecord.submissionId.toString());
    formData.append('reviewSheetTemplateId', selectedTemplate!.id.toString());
    formData.append('content', content);
    formData.append('apContactName', apContactName);
    formData.append('number', applyCode);
    formData.append('title', englishTitle);
    formData.append('signature', signature);
    formData.append('email', email);
    formData.append('emailCc', emailCc);
    formData.append('subject', subject);

    // Append each file to the FormData
    attachments.forEach(attachment => {
      formData.append('attachments', attachment.file); // Use the same key for multiple files

      if (attachment.attachmentId) {
        formData.append('attachmentIds', attachment.attachmentId.toString());
      }
    });

    setShowAttachmentModal(false);
    setIsRequesting(true);
    setShowFeedbackModal(true);
    setFeedbackMessage(undefined);

    reviewSheetService
      .newReviewSheet(formData)
      .promise.then((reviewDataDTO: ReviewDataDTO) => {
        setAttachments([]);
        setFeedbackMessage({ positive: 'Email Successfully Sent!' });
        dispatch(pushReview(reviewDataDTO));
        dispatch(setEnableReview(false));
        setActiveStep(steps[0].id);
        setSubject(defaultSubject);
      })
      .catch((err: AxiosError) => {
        const errorMessage = (err.response?.data as { message: string }).message;

        if (err.response?.status === 404 && errorMessage.includes('Template')) {
          setFeedbackMessage({
            negative: 'The selected template does not exist anymore. Please choose a different one and try again.',
          });
        } else if (err.response?.status === 400 && errorMessage.includes('Template')) {
          setFeedbackMessage({
            negative: 'The selected template is disabled. Please choose a different one and try again.',
          });
        } else {
          setFeedbackMessage({
            negative: (
              <>
                Email <strong>NOT</strong> Sent!
              </>
            ),
          });
        }
        console.error(err);
      })
      .finally(() => {
        setIsRequesting(false);
      });
  }

  const handleBackToHome = () => {
    navigate('/');
  };

  const handleSubmmitModal = async (e: any) => {
    setShowFeedbackModal(false);
    handleModal(false);
  };

  function getStepIndex(stepId: string): number {
    return steps.findIndex((step: ReviewStep) => stepId === step.id);
  }

  function handleDrop(event: DragEvent) {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;

    addFiles(droppedFiles);
  }

  function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    const files = event.target.files;

    addFiles(files);
  }

  function addFiles(files: FileList | null) {
    setAttachErrorMessage('');

    if (files && files.length > 0) {
      const newAttachments: FileExtended[] = Array.from(files).map((file: File) => ({
        file,
        progress: 0,
        status: FileStatus.PENDING,
      }));

      const filteredDuplicatedAttachments = filterDuplicateFiles([...attachments, ...newAttachments]);
      const filteredFilesThatExceedLimit = filterFilesThatExceedLimit(filteredDuplicatedAttachments);

      setAttachments(filteredFilesThatExceedLimit);
    }
  }

  function filterFilesThatExceedLimit(files: FileExtended[]): FileExtended[] {
    const maxTotalSize = 24 * 1024 * 1024; // 24MB

    // Initialize variables to track the total size and the files to keep
    let totalSize = 0;
    const validFiles: FileExtended[] = [];

    // Iterate through the files and add them as long as the total size remains under 30MB
    for (const fileExtended of files) {
      const fileSize = fileExtended.file.size;

      // Check if adding this file would exceed the size limit
      if (totalSize + fileSize <= maxTotalSize) {
        validFiles.push(fileExtended);
        totalSize += fileSize;
      } else {
        break;
      }
    }

    // If there are files that couldn't be added, show an error message
    if (validFiles.length !== files.length) {
      setAttachErrorMessage(
        'Some files were not added to the upload list because the sum of their sizes exceeds 24MB.',
      );
    }

    return validFiles;
  }

  function filterDuplicateFiles(files: FileExtended[]): FileExtended[] {
    const seenNames = new Set<string>();

    return files.filter((fileExtended: FileExtended) => {
      if (seenNames.has(fileExtended.file.name)) {
        setAttachErrorMessage('Some files were not added to the upload list due to duplicate names.');
        return false; // Duplicate found
      }

      seenNames.add(fileExtended.file.name);

      return true; // Unique file
    });
  }

  function removeFile(fileExtended: FileExtended): void {
    if (attachments.length > 0) {
      const filesToBeUploadedCopy = [...attachments];
      const fileIndex = filesToBeUploadedCopy.indexOf(fileExtended);

      if (fileIndex > -1) {
        filesToBeUploadedCopy.splice(fileIndex, 1);
      }

      setAttachments(filesToBeUploadedCopy);

      if (fileExtended.attachmentId) {
        attachmentService.deleteAttachment(fileExtended.attachmentId).promise.catch(error => {
          console.error(error);
        });
      }
    }
  }

  function uploadFiles(filesParam: FileExtended[]) {
    if (filesParam.length > 0) {
      for (let index = 0; index < filesParam.length; index++) {
        const file = filesParam[index];

        if (file.status !== FileStatus.PENDING) {
          continue;
        }

        uploadFile(file);
      }
    }
  }

  function refreshUpload(fileExtended: FileExtended) {
    uploadFile(fileExtended);
  }

  function uploadFile(fileExtended: FileExtended) {
    const attachment: Attachment = {
      submissionId: currentRecord.submissionId,
      fileName: fileExtended.file.name,
      fileType: getMimeType(fileExtended.file.name),
      fileSize: fileExtended.file.size,
    };

    updateFileStatus(fileExtended, FileStatus.UPLOADING);

    attachmentService
      .signEmailAttachment(attachment)
      .promise.then((res: any) => {
        const signedUrl: string = res.signedUrl;
        const attachmentId: number = res.attachmentId;
        const xhr = new XMLHttpRequest();

        xhr.open('PUT', signedUrl, true);
        xhr.setRequestHeader('Content-Type', attachment.fileType);

        xhr.upload.onprogress = event => updateFileProgress(event, fileExtended);

        xhr.onload = () => {
          if (xhr.status === 200) {
            attachmentService
              .updateStatusToUploaded(attachmentId)
              .promise.then((attachment: AttachmentDTO) => {
                setAttachmentId(fileExtended, attachment.id);
              })
              .catch(error => console.error(error));
            updateFileStatus(fileExtended, FileStatus.UPLOADED);
          } else {
            attachmentService.updateStatusToFailed(attachmentId).promise.catch(error => console.error(error));
            updateFileStatus(fileExtended, FileStatus.FAILED);
          }
        };

        xhr.onerror = () => {
          attachmentService.updateStatusToFailed(attachmentId).promise.catch(error => console.error(error));
          updateFileStatus(fileExtended, FileStatus.FAILED);
        };

        xhr.send(fileExtended.file);
      })
      .catch(error => {
        console.error(error);
        updateFileStatus(fileExtended, FileStatus.FAILED);
      });
  }

  function updateFileStatus(fileExtended: FileExtended, status: string) {
    if (status === FileStatus.FAILED) {
      setAttachErrorMessage('One or more files could not be attached. Please remove them or try attaching them again.');
    }
    fileExtended.status = status;
    updateFile(fileExtended);
  }

  function setAttachmentId(fileExtended: FileExtended, attachmentId: number) {
    fileExtended.attachmentId = attachmentId;
    updateFile(fileExtended);
  }

  function updateFile(fileExtended: FileExtended) {
    if (attachments) {
      const filesToBeUploadedCopy = [...attachments];
      const fileIndex = filesToBeUploadedCopy.indexOf(fileExtended);

      if (fileIndex > -1) {
        filesToBeUploadedCopy[fileIndex] = fileExtended;
      }

      setAttachments(filesToBeUploadedCopy);
    }
  }

  function updateFileProgress(event: ProgressEvent<EventTarget>, fileExtended: FileExtended) {
    if (event.lengthComputable) {
      const percentComplete = Math.round((event.loaded / event.total) * 100);
      fileExtended.progress = percentComplete;

      updateFile(fileExtended);
    }
  }

  function onBlurJodit(newContent: string) {
    const newContentText = removeHtmlTags(newContent);
    const defaultContentText = removeHtmlTags(defaultContent);

    if (newContentText !== defaultContentText) {
      dispatch(reviewReducer.setHasChanges(true));
    } else {
      dispatch(reviewReducer.setHasChanges(false));
    }

    setContent(newContent);
  }

  function removeHtmlTags(input: string): string {
    // Remove HTML tags
    let result = input.replace(/<[^>]*>/g, '');

    // Normalize internal whitespace (replace multiple spaces, newlines with a single space)
    result = result.replace(/\s+/g, ' ').trim();

    return result;
  }

  return isLoading ? (
    <LoadingScreen styles={{ height: '650px' }} />
  ) : (
    <div className="reviewTab contentCards">
      <Paper className="contentCard paperReview" variant="outlined">
        <div className="paper-title">
          <span className="paperText">Review Sheet</span>
        </div>

        {hasTheReviewBeenSent(lastReview) && !enableReview ? (
          <div className="contentPreview">{parse(lastReview.reviewSheet.content || '')}</div>
        ) : (
          <>
            <div className="reviewContent">
              <Stepper alternativeLabel activeStep={getStepIndex(activeStep)} connector={<QontoConnector />}>
                {steps.map((step: ReviewStep, index: number) => (
                  <Step key={step.id}>
                    <StepLabel StepIconComponent={QontoStepIcon}>{`${index + 1}. ${step.label}`}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              {/* step1 Select Template */}
              {activeStep === ReviewSteps.SELECT_TEMPLATE && templates ? (
                <div className="templateContainer">
                  {templates
                    .filter(template => !template.disabled)
                    .map((template: any, index: number) => (
                      <button
                        className="template"
                        key={index}
                        onClick={() => handleTemplateClick(template)}
                        style={{
                          color: selectedTemplate === template ? '#3478F6' : '#DCDCDC', // Change text color
                          borderColor: selectedTemplate === template ? '#3478F6' : '#DCDCDC', // Change border color
                        }}>
                        <IoDocumentTextOutline className={selectedTemplate === template ? 'selected' : ''} />
                        <p className={selectedTemplate === template ? 'selected' : ''}>{template.name}</p>
                      </button>
                    ))}
                </div>
              ) : (
                <></>
              )}

              {/* step2 Fill in information */}
              {activeStep === ReviewSteps.FILL_INFORMATION ? (
                <div className="informationContainer">
                  <div className="inputContainer">
                    <span>Template</span>
                    <DropdownBtn
                      id="template"
                      placeholder={selectedTemplate?.name || ''}
                      required={true}
                      items={templates
                        .filter(template => !template.disabled)
                        .map((template: ReviewSheetTemplate) => {
                          return { id: template.id.toString(), label: template.name };
                        })}
                      onClick={handleTemplateClick}
                    />
                  </div>
                  {showApLegalEntityName ? (
                    <div className="inputContainer">
                      <span>AP Legal Entity Name</span>
                      <input value={apLegalEntityName} onChange={e => setApLegalEntityName(e.target.value)} />
                    </div>
                  ) : (
                    <></>
                  )}
                  {showApplyCode ? (
                    <div className="inputContainer">
                      <span>Number</span>
                      <input value={applyCode} onChange={e => setApplyCode(e.target.value)} />
                    </div>
                  ) : (
                    <></>
                  )}
                  {showBsmStatus ? (
                    <div className="inputContainer">
                      <span>Status</span>
                      <input value={bsmStatus} onChange={e => setBsmStatus(e.target.value)} />
                    </div>
                  ) : (
                    <></>
                  )}
                  {showTitleEnglish ? (
                    <div className="inputContainer">
                      <span>Title</span>
                      <input value={englishTitle} onChange={e => setTitleEnglish(e.target.value)} />
                    </div>
                  ) : (
                    <></>
                  )}
                  {showSignature ? (
                    <div className="inputContainer">
                      <span>Your Signature</span>
                      <input value={signature} onChange={e => setSignature(e.target.value)} />
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="inputContainer">
                    <span>Email</span>
                    <input
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      className={!isValidEmailState ? 'input-error' : ''}
                    />
                    {/* Conditionally render an error message */}
                    {!isValidEmailState && <div className="error-message">Please enter a valid email address.</div>}
                  </div>
                  <div className="inputContainer">
                    <span>Email CC</span>
                    <input
                      value={emailCc}
                      onChange={e => setEmailCc(e.target.value)}
                      className={!isValidEmailCcState ? 'input-error' : ''}
                    />
                    {/* Conditionally render an error message */}
                    {!isValidEmailCcState && <div className="error-message">Please enter a valid email address.</div>}
                  </div>
                </div>
              ) : (
                <></>
              )}

              {/* step3 Attach files */}
              {activeStep === ReviewSteps.ATTACH_FILE ? (
                <div className="informationContainer attach-files-container">
                  {attachErrorMessage && (
                    <div className="review-alert-container">
                      <AlertComponent
                        text={attachErrorMessage}
                        type="danger"
                        onClick={() => setAttachErrorMessage('')}
                        buttonText="X"
                      />
                    </div>
                  )}
                  <div className="upload-drop-zone" onDrop={handleDrop} onDragOver={event => event.preventDefault()}>
                    <FaFileUpload size={24} color="#5E677B" />
                    <div className="upload-drop-zone-text">
                      <span>
                        Drag & drop your files here or &nbsp;
                        <label htmlFor="review-tab-file-input" className="upload-choose-file-button">
                          choose files
                        </label>
                        <input
                          className="upload-choose-file-input"
                          type="file"
                          id="review-tab-file-input"
                          multiple
                          onChange={handleFileChange}
                        />
                        .
                      </span>
                      <span className="upload-drop-zone-max-size">
                        <FiInfo size={14} />
                        Maximum total attachment size: <strong>24 MB</strong>
                      </span>
                    </div>
                  </div>
                  {attachments.length > 0 && (
                    <div className="files-upload-cards">
                      {attachments.map((fileExtended: FileExtended, index: number) => (
                        <div className="files-upload-card" key={index}>
                          <div className="files-upload-card-body">
                            {selectFileIcon(fileExtended.file.type)}
                            <div className="files-upload-card-content">
                              <span
                                className="files-upload-card-title"
                                data-tooltip-id="review-tooltip"
                                data-tooltip-content={fileExtended.file.name.length > 20 ? fileExtended.file.name : ''}>
                                {fileExtended.file.name}
                              </span>
                              <div className="files-upload-card-infos">
                                <span className="files-upload-card-info">{formatBytes(fileExtended.file.size, 1)}</span>
                                {fileExtended.status === FileStatus.FAILED && (
                                  <>
                                    <GoDotFill size={6} color="#5E677B" />
                                    <span className="files-upload-card-error">{`${
                                      fileExtended.error ? fileExtended.error : 'Upload failed'
                                    }`}</span>
                                  </>
                                )}
                                {fileExtended.status === FileStatus.UPLOADED && (
                                  <>
                                    <GoDotFill size={6} color="#5E677B" />
                                    <span className="files-upload-card-completed">Upload completed</span>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="files-upload-card-actions">
                              {fileExtended.status !== FileStatus.UPLOADING && (
                                <FaRegTrashAlt size={18} color="#5E677B" onClick={() => removeFile(fileExtended)} />
                              )}
                              {fileExtended.status === FileStatus.FAILED && (
                                <MdRefresh size={22} color="#5E677B" onClick={() => refreshUpload(fileExtended)} />
                              )}
                            </div>
                          </div>
                          {fileExtended.status === FileStatus.UPLOADING && (
                            <div className="progress-bar-track">
                              <div
                                id="myBar"
                                className="progress-bar"
                                style={{
                                  width: `${fileExtended.progress || 0}%`,
                                }}></div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}

              {/* step4 Preview & Submit */}
              {activeStep === ReviewSteps.PREVIEW_AND_SUBMIT ? (
                <div className="rteContainer">
                  {previewAndSubmitErrorMessage && (
                    <div className="review-alert-container">
                      <AlertComponent
                        text={previewAndSubmitErrorMessage}
                        type="danger"
                        onClick={() => setPreviewAndSubmitErrorMessage('')}
                        buttonText="X"
                      />
                    </div>
                  )}
                  <div className="subject-input-container">
                    <label htmlFor="subject-input">Subject: </label>
                    <input
                      id="subject-input"
                      name="subject"
                      type="text"
                      placeholder="Enter the subject"
                      value={subject}
                      onChange={e => setSubject(e.target.value)}
                    />
                  </div>
                  <JoditEditor
                    ref={editor}
                    value={content}
                    config={{
                      removeButtons: [
                        'classSpan',
                        'file',
                        'video',
                        'speechRecognize',
                        'copyformat',
                        'symbols',
                        'ai-commands',
                        'ai-assistant',
                        'find',
                        'source',
                        'about',
                      ],
                    }}
                    onBlur={newContent => onBlurJodit(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={newContent => {}}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="actionsContainer">
              <div className="leftContentContainer">
                {getStepIndex(activeStep) === steps.length - 1 ? (
                  <div className="leftContent">
                    <button className="copyButton" onClick={handleCopy}>
                      <MdOutlineFileCopy
                        data-tooltip-id="review-tooltip"
                        data-tooltip-content="Copy review to clipboard"
                      />
                    </button>
                    <button className="pdfButton" onClick={handlePdf}>
                      <MdOutlinePictureAsPdf
                        data-tooltip-id="review-tooltip"
                        data-tooltip-content="Download review as PDF"
                      />
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="rightContent">
                {getStepIndex(activeStep) === steps.length - 1 ? <span>Send to: {email}</span> : <></>}
                <Button className="previousButton" disabled={getStepIndex(activeStep) === 0} onClick={handleBack}>
                  Previous
                </Button>
                <Button
                  className="nextButton"
                  disabled={isNextButtonDisabled()}
                  onClick={getStepIndex(activeStep) === steps.length - 1 ? () => handleModal() : handleNext}>
                  {getStepIndex(activeStep) === steps.length - 1 ? 'Submit' : 'Next'}
                </Button>
              </div>
            </div>
          </>
        )}
      </Paper>
      <Modal
        className="customModal"
        size="sm"
        show={showFeedbackModal}
        onHide={() => setShowFeedbackModal(false)}
        aria-labelledby="ReviewSheetFeedbackModal">
        <Modal.Header closeButton>
          <Modal.Title className="modalTitle" id="ReviewSheetFeedbackModal">
            Submit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBody">
          {isRequesting ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '32px',
              }}>
              <CircularProgress color="inherit" size={40} />
            </div>
          ) : (
            <div className={`bodyContainer ${feedbackMessage?.positive ? 'success' : 'fail'}`}>
              {feedbackMessage?.positive && (
                <>
                  <CgCheckO />
                  <p>{feedbackMessage?.positive}</p>
                </>
              )}

              {feedbackMessage?.negative && (
                <>
                  <CgDanger />
                  <p>{feedbackMessage?.negative}</p>
                </>
              )}
            </div>
          )}
        </Modal.Body>
        {isRequesting ? (
          <Modal.Footer></Modal.Footer>
        ) : (
          <Modal.Footer>
            {feedbackMessage?.positive ? (
              <Button className="button backButton" onClick={handleBackToHome}>
                Back to Home
              </Button>
            ) : (
              <div className="footerContainer">
                <Button className="button backButton" onClick={() => setShowFeedbackModal(false)}>
                  Back to Review
                </Button>
                <Button className="button submitButton" onClick={handleSubmmitModal}>
                  Try Again
                </Button>
              </div>
            )}
          </Modal.Footer>
        )}
      </Modal>
      <Modal className="attachment-warn-modal" show={showAttachmentModal} onHide={() => setShowAttachmentModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="modalTitle" id="AttachmentReminderModal">
            Attachment Reminder
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <p>No attachments were found on the revised version.</p>
          <p>Are you sure you want to continue?</p>
        </Modal.Body>
        <Modal.Footer>
          <div className="footer-content">
            <Button className="button send" onClick={() => handleModal(false)}>
              Send
            </Button>
            <Button className="button dont-send" onClick={() => setShowAttachmentModal(false)}>
              Don't send
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Tooltip id="review-tooltip" place="top" />
    </div>
  );
}
