import { Paper } from '@mui/material';
import './index.scss';
import moment from 'moment';
import SelectComponent from '../../../components/ui/select-component';
import { IOption } from '../../../components/ui/multi-select-dropdown';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { capitalizeWords } from '../../../utils/string-utils';
import { Affiliate } from '../../../interface/affiliate';
import { ApprovedProvider } from '../../../interface/approved-provider';
import LoaderComponent from '../../../components/ui/loader';
import reportsService from '../../../resources/reports/reports.service';
import AlertComponent from '../../../components/ui/alert';
import { MediaType } from '../../../interface/media-type';
import { Assignee } from '../../../interface/assignee';
import { WorkflowStatus } from '../../../interface/workflow-status';
import { useFilterOptions } from '../../../hooks/use-filter-options';

interface FormState {
  [key: string]: {
    value: string;
    label: string;
  };
}

const currentDay = moment().format('YYYY-MM-DD');
const firstMonthDay = moment().startOf('month').format('YYYY-MM-DD');

const defaultSubmissionDetailsFormState = {
  startDate: { value: firstMonthDay, label: firstMonthDay },
  endDate: { value: currentDay, label: currentDay },
  affiliates: { value: '', label: '' },
  approvedProviders: { value: '', label: '' },
  assignees: { value: '', label: '' },
  workflowStatuses: { value: '', label: '' },
  mediaTypes: { value: '', label: '' },
};

export default function DataExtraction() {
  const [reportFormState, setReportFormState] = useState<FormState>(defaultSubmissionDetailsFormState);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const { affiliates, approvedProviders, assignees, workflowStatuses, mediaTypes } = useAppSelector(
    state => state.options,
  );
  const { allAffiliates, allApprovedProviders, allAssignees, allStatus, allMediaTypes } = useFilterOptions({
    affiliates,
    approvedProviders,
    assignees,
    workflowStatuses,
    mediaTypes,
  });

  useEffect(() => {
    handleMultiSelectInputChange('affiliates', allAffiliates);
    handleMultiSelectInputChange('approvedProviders', allApprovedProviders);
    handleMultiSelectInputChange('assignees', allAssignees);
    handleMultiSelectInputChange('workflowStatuses', allStatus);
    handleMultiSelectInputChange('mediaTypes', allMediaTypes);
  }, [affiliates, approvedProviders, assignees, workflowStatuses, mediaTypes]);

  function handleDateInputs(inputName: string, dateP: string) {
    let date = dateP;

    if (moment(date).isAfter(moment())) {
      date = moment().format('YYYY-MM-DD');
    }

    setReportFormState(prevState => ({
      ...prevState,
      [inputName]: {
        value: date,
        label: date,
      },
    }));
  }

  function handleMultiSelectInputChange(name: string, options: IOption[]) {
    const values = options.map(option => option.value).join(', ');
    const labels = options.map(option => option.label).join(', ');

    setReportFormState(prevState => ({
      ...prevState,
      [name]: {
        value: values,
        label: labels,
      },
    }));
  }

  function downloadReport() {
    setErrorMessage('');

    const processedParams = {
      startDate: reportFormState.startDate.value,
      endDate: reportFormState.endDate.value,
      affiliates: reportFormState.affiliates.value || '',
      approvedProviders: reportFormState.approvedProviders.value || '',
      assignees: reportFormState.assignees.value || '',
      workflowStatuses: reportFormState.workflowStatuses.value || '',
      mediaTypes: reportFormState.mediaTypes.value || '',
    };

    if (!processedParams.startDate || !processedParams.endDate) {
      setErrorMessage('Start Date and End Date are required');
      return;
    }

    if (moment(processedParams.startDate).isAfter(processedParams.endDate)) {
      setErrorMessage('Start Date cannot be later than End Date.');
      return;
    }

    if (moment(processedParams.endDate).diff(moment(processedParams.startDate), 'days') > 365) {
      setErrorMessage('The date range cannot exceed 365 days.');
      return;
    }

    if (
      reportFormState.affiliates.value.length === 0 ||
      reportFormState.approvedProviders.value.length === 0 ||
      reportFormState.assignees.value.length === 0 ||
      reportFormState.workflowStatuses.value.length === 0 ||
      reportFormState.mediaTypes.value.length === 0
    ) {
      setErrorMessage(
        'Please select at least one option for Affiliate, Approved Provider, Assigned To, Current Status, and BSM Type.',
      );
      return;
    }

    setIsDownloading(true);

    reportsService
      .getXlsxReport(
        processedParams.startDate,
        processedParams.endDate,
        processedParams.affiliates,
        processedParams.approvedProviders,
        processedParams.assignees,
        processedParams.workflowStatuses,
        processedParams.mediaTypes,
      )
      .promise.then((response: any) => {
        downloadFile(response, `submissions_report_${processedParams.startDate}_${processedParams.endDate}`);
      })
      .catch((e: Error) => {
        console.error('Error downloading report', e);
        setErrorMessage(`Unknown error:  ${e.message}`);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  }

  function downloadFile(response: any, fileName: string) {
    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.xlsx`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  return (
    <div className="data-extraction-container">
      {errorMessage && (
        <AlertComponent text={errorMessage} type="danger" onClick={() => setErrorMessage('')} buttonText="X" />
      )}
      {successMessage && (
        <AlertComponent text={successMessage} type="success" onClick={() => setSuccessMessage('')} buttonText="X" />
      )}
      <Paper className="data-extraction-paper" variant="outlined">
        <div className="cc-paper-title">
          <span className="paperText">Filters</span>
        </div>
        <div className="data-extraction-content">
          <div className="data-extraction-inputs">
            <div className="data-extraction-inputs-row">
              <div className="data-extraction-input-container">
                <label htmlFor="start-date" className="data-extraction-input-label">
                  Start Date:{' '}
                </label>
                <input
                  className="cc-input"
                  type="date"
                  id="start-date"
                  max={moment().format('YYYY-MM-DD')}
                  onChange={e => {
                    handleDateInputs('startDate', e.target.value);
                  }}
                  value={reportFormState.startDate.label}
                />
              </div>
              <div className="data-extraction-input-container">
                <label htmlFor="end-date" className="data-extraction-input-label">
                  End Date:{' '}
                </label>
                <input
                  className="cc-input"
                  type="date"
                  id="end-date"
                  max={moment().format('YYYY-MM-DD')}
                  onChange={e => {
                    handleDateInputs('endDate', e.target.value);
                  }}
                  value={reportFormState.endDate.label}
                />
              </div>
            </div>
            <SelectComponent
              label="Affiliate: "
              name="data-extraction-affiliate"
              onChange={(option: IOption[]) => handleMultiSelectInputChange('affiliates', option)}
              options={allAffiliates}
              selectedOption={
                (reportFormState.affiliates as IOption).value
                  ? (reportFormState.affiliates as IOption).value.split(', ').map((val, index) => ({
                      value: val,
                      label: (reportFormState.affiliates as IOption).label.split(', ')[index],
                    }))
                  : []
              }
              labelDirection="column"
              multiple={true}
              toggleSelection={true}
            />
            <SelectComponent
              label="Approved Provider: "
              name="data-extraction-ap"
              onChange={(option: IOption[]) => handleMultiSelectInputChange('approvedProviders', option)}
              options={allApprovedProviders}
              selectedOption={
                (reportFormState.approvedProviders as IOption).value
                  ? (reportFormState.approvedProviders as IOption).value.split(', ').map((val, index) => ({
                      value: val,
                      label: (reportFormState.approvedProviders as IOption).label.split(', ')[index],
                    }))
                  : []
              }
              labelDirection="column"
              multiple={true}
              toggleSelection={true}
            />
            <SelectComponent
              label="BSM Type: "
              name="bsm-type"
              onChange={(option: IOption[]) => handleMultiSelectInputChange('mediaTypes', option)}
              options={allMediaTypes}
              selectedOption={
                (reportFormState.mediaTypes as IOption).value
                  ? (reportFormState.mediaTypes as IOption).value.split(', ').map((val, index) => ({
                      value: val,
                      label: (reportFormState.mediaTypes as IOption).label.split(', ')[index],
                    }))
                  : []
              }
              labelDirection="column"
              multiple={true}
              toggleSelection={true}
            />
            <SelectComponent
              label="Assigned To: "
              name="assigned-to"
              onChange={(option: IOption[]) => handleMultiSelectInputChange('assignees', option)}
              options={allAssignees}
              selectedOption={
                (reportFormState.assignees as IOption).value
                  ? (reportFormState.assignees as IOption).value.split(', ').map((val, index) => ({
                      value: val,
                      label: (reportFormState.assignees as IOption).label.split(', ')[index],
                    }))
                  : []
              }
              labelDirection="column"
              multiple={true}
              toggleSelection={true}
            />
            <SelectComponent
              label="Current status: "
              name="current-status"
              onChange={(option: IOption[]) => handleMultiSelectInputChange('workflowStatuses', option)}
              options={allStatus}
              selectedOption={
                (reportFormState.workflowStatuses as IOption).value
                  ? (reportFormState.workflowStatuses as IOption).value.split(', ').map((val, index) => ({
                      value: val,
                      label: (reportFormState.workflowStatuses as IOption).label.split(', ')[index],
                    }))
                  : []
              }
              labelDirection="column"
              multiple={true}
              toggleSelection={true}
            />
          </div>
          <div className="button-container">
            <button
              onMouseDown={e => e.preventDefault()}
              onClick={() => downloadReport()}
              disabled={isDownloading}
              className="cc-button cc-confirm-button">
              {isDownloading ? (
                <LoaderComponent styles={{ border: '0.2em solid white', borderTop: '0.2em solid transparent' }} />
              ) : (
                'Download report'
              )}
            </button>
          </div>
        </div>
      </Paper>
    </div>
  );
}
