import { Divider, Paper } from '@mui/material';
import './index.scss';
import { FaPlus } from 'react-icons/fa';
import useViolationRule from '../../resources/violation-rule/violation-rule-hook';
import { useEffect, useRef, useState } from 'react';
import { ViolationRule } from '../../resources/violation-rule/violation-rule-types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import * as transcriptReducer from '../../redux/reducers/transcriptReducer';
import { BsThreeDotsVertical } from 'react-icons/bs';
import SelectComponent from '../../components/ui/select-component';
import { IOption } from '../../components/ui/multi-select-dropdown';
import LoaderComponent from '../../components/ui/loader';
import LoadingScreen from '../loading';
import { MdInfoOutline } from 'react-icons/md';
import { BsChevronRight } from 'react-icons/bs';
import violationRuleService from '../../resources/violation-rule/violation-rule.service';
import AlertComponent from '../../components/ui/alert';
import AlertMessages from '../../resources/messages/alerts.json';
import { Tooltip } from 'react-tooltip';

const DEFAULT_RIGHT_PAPER_TITLE = 'Select an Error Code';

const defaultViolationRule = {
  id: -1,
  name: '',
  parentId: -1,
  severity: -1,
  errorCode: '',
  errorDescription: '',
  enabled: false,
};

interface ViolationRuleRelatives {
  level1: ViolationRule;
  level2: ViolationRule | null;
  level3: ViolationRule | null;
}

interface ErrorCodeManagementProps {}

export default function ErrorCodeManagement({}: ErrorCodeManagementProps) {
  const { violationRules, fetchAllViolationRules, isRequestingViolationRules } = useViolationRule();
  const nestedViolationRules: ViolationRule[] = useAppSelector(state => state.transcript.nestedViolationRules);
  const [clickedMenuId, setClickedMenuId] = useState<number | null>(null);
  const [selectedViolationRule, setSelectedViolationRule] = useState<ViolationRule | null>(null);
  const [selectedViolationRuleRelatives, setSelectedViolationRuleRelatives] = useState<ViolationRuleRelatives | null>(
    null,
  );
  const [rightPaperTitle, setRightPaperTitle] = useState<string>(
    selectedViolationRule?.name || DEFAULT_RIGHT_PAPER_TITLE,
  );
  const [isCreating, setIsCreating] = useState<{ level: number; clickedId: number | null } | null>(null);
  const [newViolationRule, setNewViolationRule] = useState<ViolationRule>(defaultViolationRule);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [isUpdatingEnable, setIsUpdatingEnable] = useState<boolean>(false);
  const [rightPanelErrorMessage, setRightPanelErrorMessage] = useState<string>('');
  const [leftPanelErrorMessage, setLeftPanelErrorMessage] = useState<string>('');
  const itemMenu = useRef(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedViolationRule) {
      setRelatives(selectedViolationRule);
      setRightPaperTitle(selectedViolationRule.name);
    } else if (!isCreating) {
      setRightPaperTitle(DEFAULT_RIGHT_PAPER_TITLE);
    }
  }, [selectedViolationRule]);

  useEffect(() => {
    if (!violationRules.data) {
      fetchAllViolationRules();
    }
  }, []);

  // Threat the violation rules to be a nested object.
  useEffect(() => {
    if (violationRules.data) {
      // Create a deep copy of violationRules.data to avoid mutating the original objects.
      const deepCopyData = JSON.parse(JSON.stringify(violationRules.data));
      const nestedViolationRules: ViolationRule[] = [];
      const ruleMap = new Map();

      // Initialize ruleMap with deep copied data.
      deepCopyData.forEach((rule: ViolationRule) => {
        rule.children = [];
        ruleMap.set(rule.id, rule);
      });

      // Populate children references.
      deepCopyData.forEach((rule: ViolationRule) => {
        if (rule.parentId === null) {
          nestedViolationRules.push(rule);
        } else {
          const parentRule = ruleMap.get(rule.parentId);
          if (parentRule) {
            parentRule.children.push(rule);
          }
        }
      });

      dispatch(transcriptReducer.setViolationRules(deepCopyData));
      dispatch(transcriptReducer.setNestedViolationRules(nestedViolationRules));
    }
  }, [violationRules.data]);

  function openLevelMenu(clickedItem: number) {
    if (clickedMenuId === clickedItem) {
      setClickedMenuId(null);
    } else {
      setClickedMenuId(clickedItem);
    }
  }

  function collapseItems(clickedItem: number) {
    const childBox = document.getElementById('child-box-' + clickedItem);
    const chevronIcon = document.getElementById('chevron-icon-' + clickedItem);

    // Toggle display property between block and none
    if (childBox && childBox.style.display == 'none') {
      childBox.style.display = 'block';
      if (chevronIcon) {
        chevronIcon.style.transform = 'rotate(90deg)';
      }
    } else if (childBox) {
      childBox.style.display = 'none';
      if (chevronIcon) {
        chevronIcon.style.transform = 'rotate(0deg)';
      }
    }
  }

  function selectViolationRule(violationRule: ViolationRule) {
    setIsCreating(null);
    setSelectedViolationRule(violationRule);
    setClickedMenuId(null);
  }

  function cancel() {
    resetState();
  }

  function resetState() {
    setSelectedViolationRule(null);
    setSelectedViolationRuleRelatives(null);
    setIsCreating(null);
    setNewViolationRule(defaultViolationRule);
  }

  function getViolationRule(violationRuleId: number): ViolationRule | null {
    const foundViolationRule = violationRules.data?.find(
      (violationRule: ViolationRule) => violationRule.id === violationRuleId,
    );

    return foundViolationRule || null;
  }

  function setRelatives(violationRule: ViolationRule) {
    if (!violationRule.parentId) {
      return setSelectedViolationRuleRelatives({
        level1: violationRule,
        level2: null,
        level3: null,
      });
    }

    const parent = getViolationRule(violationRule.parentId);
    if (!parent) {
      return setSelectedViolationRuleRelatives({
        level1: violationRule,
        level2: null,
        level3: null,
      });
    }

    const grandParent = parent.parentId ? getViolationRule(parent.parentId) : null;

    setSelectedViolationRuleRelatives({
      level1: grandParent ?? parent,
      level2: grandParent ? parent : violationRule,
      level3: grandParent ? violationRule : null,
    });
  }

  function level1Options(): IOption[] {
    const options: IOption[] = [];

    for (let rv of nestedViolationRules) {
      options.push({ value: rv.id.toString(), label: rv.name });
    }

    return options;
  }

  function childrenAsOptions(violationRuleId: number): IOption[] {
    const children = nestedViolationRules.find(
      (violationRule: ViolationRule) => violationRule.id === violationRuleId,
    )?.children;
    const options: IOption[] = [];

    if (children) {
      for (let rv of children) {
        options.push({ value: rv.id.toString(), label: rv.name });
      }
    }

    return options;
  }

  function setLevelOneRelative(option: IOption) {
    const violationRuleId = Number.parseInt(option.value, 10);
    const violationRule = getViolationRule(violationRuleId);

    if (violationRule) {
      setSelectedViolationRule({
        ...selectedViolationRule,
        parentId: violationRuleId,
      } as ViolationRule);

      setSelectedViolationRuleRelatives({
        level1: violationRule,
        level2: selectedViolationRuleRelatives?.level2 || null,
        level3: selectedViolationRuleRelatives?.level3 || null,
      });
    }
  }

  function setLevelTwoRelative(option: IOption) {
    const violationRuleId = Number.parseInt(option.value, 10);
    const violationRule = getViolationRule(violationRuleId);

    if (violationRule) {
      setSelectedViolationRule({
        ...selectedViolationRule,
        parentId: violationRuleId,
      } as ViolationRule);

      setSelectedViolationRuleRelatives({
        level1: selectedViolationRuleRelatives!.level1,
        level2: violationRule,
        level3: selectedViolationRuleRelatives?.level3 || null,
      });
    }
  }

  function setViolationRuleEnabled(e: React.ChangeEvent<HTMLInputElement>) {
    setSelectedViolationRule({
      ...selectedViolationRule,
      enabled: e.target.checked,
    } as ViolationRule);
  }

  function editViolationRule() {
    setRightPanelErrorMessage('');

    if (!selectedViolationRule?.name || selectedViolationRule?.name.trim().length === 0) {
      setRightPanelErrorMessage('Level name is required');
      return;
    }

    if (selectedViolationRule?.parentId) {
      const currentselectedViolationRule = getViolationRule(selectedViolationRule?.id);
      const selectedViolationRuleParent = getViolationRule(selectedViolationRule?.parentId);

      if (
        !selectedViolationRuleParent?.enabled &&
        selectedViolationRule.enabled !== currentselectedViolationRule?.enabled
      ) {
        setRightPanelErrorMessage(AlertMessages.CANNOT_ENABLE_VIOLATION_RULE);
        return;
      }
    }

    setIsRequesting(true);

    violationRuleService
      .editViolationRule(selectedViolationRule!)
      .promise.then(() => {
        fetchAllViolationRules();
      })
      .catch(error => {
        const errorMessage: string = error.response?.data?.message;

        if (errorMessage) {
          setRightPanelErrorMessage(errorMessage);
        } else {
          setRightPanelErrorMessage(`Unknow error: ${error}`);
        }
      })
      .finally(() => {
        setIsRequesting(false);
      });
  }

  function createViolationRule() {
    setRightPanelErrorMessage('');

    const foundViolationRule = violationRules.data?.find(
      (violationRule: ViolationRule) => violationRule.errorCode === newViolationRule.errorCode,
    );

    if (foundViolationRule && newViolationRule.errorCode) {
      setRightPanelErrorMessage(`The Error Code ${newViolationRule.errorCode} already exists`);
      return;
    }

    if (!newViolationRule.name || newViolationRule.name.trim().length === 0) {
      setRightPanelErrorMessage('Level name is required');
      return;
    }

    if (!newViolationRule.errorCode) {
      setRightPanelErrorMessage('Error Code is required');
      return;
    }

    const processedViolationRule: Partial<ViolationRule> = {
      parentId: newViolationRule.parentId === -1 ? null : newViolationRule.parentId,
      enabled: newViolationRule.enabled,
      errorCode: newViolationRule.errorCode,
      errorDescription: newViolationRule.errorDescription,
      name: newViolationRule.name,
    };

    setIsRequesting(true);

    violationRuleService
      .createViolationRule(processedViolationRule)
      .promise.then(() => {
        fetchAllViolationRules();
        if (newViolationRule.parentId) {
          const parentViolationRule = getViolationRule(newViolationRule.parentId);

          if (parentViolationRule?.parentId) {
            collapseItems(parentViolationRule.parentId);
          }
          collapseItems(newViolationRule.parentId);
        }
        resetState();
      })
      .catch(error => {
        const errorMessage: string = error.response?.data?.message;

        if (errorMessage) {
          setRightPanelErrorMessage(errorMessage);
        } else {
          setRightPanelErrorMessage(`Unknow error: ${error}`);
        }
      })
      .finally(() => {
        setIsRequesting(false);
      });
  }

  function updateEnabled(violationRuleId: number, enabled: boolean) {
    // close menu tab
    setClickedMenuId(null);
    resetState();

    const currentViolationRule = getViolationRule(violationRuleId);
    const currentParentViolationRule = currentViolationRule?.parentId
      ? getViolationRule(currentViolationRule?.parentId)
      : null;

    if (currentParentViolationRule !== null && !currentParentViolationRule?.enabled) {
      setLeftPanelErrorMessage(AlertMessages.CANNOT_ENABLE_VIOLATION_RULE);
      return;
    }

    setIsUpdatingEnable(true);

    violationRuleService
      .enabledViolationRule(violationRuleId, enabled)
      .promise.then(() => {
        fetchAllViolationRules();
      })
      .finally(() => {
        setIsUpdatingEnable(false);
      });
  }

  function changeViolationRuleName(event: React.ChangeEvent<HTMLInputElement>) {
    setSelectedViolationRule({
      ...selectedViolationRule,
      name: event.target.value,
    } as ViolationRule);
  }

  function changeViolationRuleErrorCode(event: React.ChangeEvent<HTMLInputElement>) {
    setSelectedViolationRule({
      ...selectedViolationRule,
      errorCode: event.target.value,
    } as ViolationRule);
  }

  function changeViolationRuleErrorDescription(event: React.ChangeEvent<HTMLInputElement>) {
    setSelectedViolationRule({
      ...selectedViolationRule,
      errorDescription: event.target.value,
    } as ViolationRule);
  }

  function addLevel(level: number, clickedId: number | null) {
    setIsCreating({ level, clickedId });
    setClickedMenuId(null);
    setSelectedViolationRule(null);
    setSelectedViolationRuleRelatives(null);
    setNewViolationRule({ ...newViolationRule, parentId: clickedId });
    setRightPaperTitle('New Error Code');
  }

  function handleNewViolationRuleChange(
    event: React.ChangeEvent<HTMLInputElement> | null,
    field: string,
    option?: IOption,
  ) {
    if (newViolationRule && event) {
      if (field === 'enabled') {
        setNewViolationRule({ ...newViolationRule, enabled: event.target.checked });
      } else {
        setNewViolationRule({ ...newViolationRule, [field]: event.target.value });
      }
    } else if (newViolationRule) {
      setNewViolationRule({ ...newViolationRule, parentId: Number.parseInt(option?.value!, 10) });
    }
  }

  function getInitials(str: string): string {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase())
      .join('');
  }

  function sortByErrorCode(a: ViolationRule, b: ViolationRule) {
    if (a.errorCode < b.errorCode) {
      return -1;
    }
    if (a.errorCode > b.errorCode) {
      return 1;
    }

    return 0;
  }

  function suggestErrorCode() {
    let suggestedErrorCode = '';
    let parentViolationRule = null;

    if (newViolationRule.parentId) {
      parentViolationRule = getViolationRule(newViolationRule.parentId);
      suggestedErrorCode += parentViolationRule?.errorCode;
    }

    if (newViolationRule.name && newViolationRule.name.length > 0) {
      if (isCreating?.level === 1) {
        suggestedErrorCode += getInitials(newViolationRule.name);
      } else if (isCreating?.level === 2) {
        suggestedErrorCode = `${suggestedErrorCode}_${getInitials(newViolationRule.name)}`;
      } else if (isCreating?.level === 3) {
        const foundViolationRules = violationRules.data?.filter((violationRule: ViolationRule) =>
          violationRule.errorCode.startsWith(suggestedErrorCode),
        );
        const sortedFoundViolationRules = foundViolationRules?.sort(sortByErrorCode);
        const lastErrorCode = sortedFoundViolationRules?.at(-1);
        const lastErrorCodeIdx = lastErrorCode?.errorCode.split('_').at(-1);

        if (lastErrorCodeIdx) {
          const lastErrorCodeIdxNumber = Number.parseInt(lastErrorCodeIdx, 10);

          if (Number.isNaN(lastErrorCodeIdxNumber)) {
            suggestedErrorCode = `${suggestedErrorCode}_0`;
          } else {
            suggestedErrorCode = `${suggestedErrorCode}_${Number.parseInt(lastErrorCodeIdx, 10) + 1}`;
          }
        }
      }
    }

    return setNewViolationRule({ ...newViolationRule, errorCode: suggestedErrorCode } as ViolationRule);
  }

  return (
    <div className="error-code-management-container">
      <div className="error-code-management-papers">
        <Paper className="error-code-management-list-container" variant="outlined">
          <LoadingScreen
            styles={{
              width: '100%',
              minHeight: '80vh',
              height: '100%',
              display: `${isRequestingViolationRules || isUpdatingEnable ? 'block' : 'none'}`,
            }}
          />
          <div style={{ display: `${isRequestingViolationRules || isUpdatingEnable ? 'none' : 'block'}` }}>
            <div className="paper-title">
              <span className="paperText">Error Code List</span>
              <div className="button-container" onClick={() => addLevel(1, null)}>
                <FaPlus size={12} color="#2cda9b" />
                <span className="button-text">Add level 1</span>
              </div>
            </div>
            <div className="error-code-management-list-content">
              {leftPanelErrorMessage && (
                <AlertComponent
                  text={leftPanelErrorMessage}
                  type="danger"
                  onClick={() => setLeftPanelErrorMessage('')}
                  buttonText="X"
                />
              )}
              <div className="vr-container">
                {nestedViolationRules.map((vr: ViolationRule, vrIndex: number) => (
                  <>
                    <div
                      className={`level1-container error-code-item ${vr.enabled ? '' : 'disabled-error-code'} ${
                        selectedViolationRule?.id === vr.id ? 'selected-error-code' : ''
                      }`}
                      id={`error-code-item-${vr.id}-${vrIndex}`}>
                      <div
                        className={`level-item-chevron ${
                          vr.children && vr.children?.length > 0 ? '' : 'level-item-chevron-disabled'
                        }`}
                        onClick={() => (vr.children && vr.children?.length > 0 ? collapseItems(vr.id) : null)}>
                        <BsChevronRight size={14} id={`chevron-icon-${vr.id}`} />
                      </div>
                      <div className="level1-name" onClick={() => selectViolationRule(vr)}>
                        {vr.name}
                      </div>
                      <div className="error-code-dots-container" onClick={() => openLevelMenu(vr.id)}>
                        <BsThreeDotsVertical size={16} />
                      </div>
                      {vr.id === clickedMenuId && (
                        <div className="level-menu" id={`level-menu-${vr.id}-${vrIndex}`}>
                          <span className="level-menu-item" onClick={() => addLevel(2, vr.id)}>
                            Add level 2
                          </span>
                          <span className="level-menu-item" onClick={() => selectViolationRule(vr)}>
                            Edit
                          </span>
                          <span className="level-menu-item" onClick={() => updateEnabled(vr.id, !vr.enabled)}>
                            {vr.enabled ? 'Disable' : 'Enable'}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="vrc-container" id={`child-box-${vr.id}`} style={{ display: 'none' }}>
                      {vr.children?.map((vrc: ViolationRule, vrcIndex: number) => (
                        <>
                          <div
                            className={`level2-container error-code-item ${vrc.enabled ? '' : 'disabled-error-code'} ${
                              selectedViolationRule?.id === vrc.id ? 'selected-error-code' : ''
                            }`}>
                            <div
                              className={`level-item-chevron ${
                                vrc.children && vrc.children?.length > 0 ? '' : 'level-item-chevron-disabled'
                              }`}
                              onClick={() => (vrc.children && vrc.children?.length > 0 ? collapseItems(vrc.id) : null)}>
                              <BsChevronRight size={14} id={`chevron-icon-${vrc.id}`} />
                            </div>
                            <div className="level2-name" onClick={() => selectViolationRule(vrc)}>
                              {vrc.name}
                            </div>
                            <div className="error-code-dots-container" onClick={() => openLevelMenu(vrc.id)}>
                              <BsThreeDotsVertical size={16} />
                            </div>
                            {vrc.id === clickedMenuId && (
                              <div className="level-menu" id={`level-menu-${vrc.id}-${vrcIndex}`}>
                                <span className="level-menu-item" onClick={() => addLevel(3, vrc.id)}>
                                  Add level 3
                                </span>
                                <span className="level-menu-item" onClick={() => selectViolationRule(vrc)}>
                                  Edit
                                </span>
                                <span className="level-menu-item" onClick={() => updateEnabled(vrc.id, !vrc.enabled)}>
                                  {vrc.enabled ? 'Disable' : 'Enable'}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="vrcc-container" id={`child-box-${vrc.id}`} style={{ display: 'none' }}>
                            {vrc.children?.map((vrcc: ViolationRule, vrccIndex: number) => (
                              <div
                                className={`level3-container error-code-item ${
                                  vrcc.enabled ? '' : 'disabled-error-code'
                                } ${selectedViolationRule?.id === vrcc.id ? 'selected-error-code' : ''}`}>
                                <div className="level3-name" onClick={() => selectViolationRule(vrcc)}>
                                  {vrcc.name}
                                </div>
                                <div className="error-code-dots-container" onClick={() => openLevelMenu(vrcc.id)}>
                                  <BsThreeDotsVertical size={16} />
                                </div>
                                {vrcc.id === clickedMenuId && (
                                  <div className="level-menu" id={`level-menu-${vrcc.id}-${vrccIndex}`}>
                                    <span className="level-menu-item" onClick={() => selectViolationRule(vrcc)}>
                                      Edit
                                    </span>
                                    <span
                                      className="level-menu-item"
                                      onClick={() => updateEnabled(vrcc.id, !vrcc.enabled)}>
                                      {vrcc.enabled ? 'Disable' : 'Enable'}
                                    </span>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </>
                      ))}
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </Paper>
        <Paper className="error-code-management-paper-container" variant="outlined">
          <div className="paper-title">
            <span className="paperText">{rightPaperTitle}</span>
          </div>
          <div className="error-code-management-content">
            {rightPanelErrorMessage && (
              <AlertComponent
                text={rightPanelErrorMessage}
                type="danger"
                onClick={() => setRightPanelErrorMessage('')}
                buttonText="X"
              />
            )}
            {isRequesting ? (
              <LoadingScreen styles={{ width: '100%', height: '80vh' }} />
            ) : selectedViolationRuleRelatives ? (
              <div className="error-code-management-form">
                {selectedViolationRuleRelatives.level2 === null && selectedViolationRuleRelatives.level3 === null ? (
                  <div className="error-code-management-input-container">
                    <label htmlFor="level1-input-text" className="error-code-management-input-label">
                      Level 1:
                    </label>
                    <input
                      className="cc-input"
                      type="text"
                      id="level1-input-text"
                      onChange={changeViolationRuleName}
                      value={selectedViolationRule?.name || ''}
                    />
                  </div>
                ) : (
                  <SelectComponent
                    label="Level 1:"
                    name="level1"
                    onChange={(option: IOption) => setLevelOneRelative(option)}
                    isDisabled={selectedViolationRuleRelatives.level3 ? true : false}
                    options={level1Options()}
                    selectedOption={
                      {
                        value: selectedViolationRuleRelatives.level1.id.toString(),
                        label: selectedViolationRuleRelatives.level1.name,
                      } as IOption
                    }
                    labelDirection="column"
                  />
                )}
                {selectedViolationRuleRelatives.level2 && selectedViolationRuleRelatives.level3 === null ? (
                  <div className="error-code-management-input-container">
                    <label htmlFor="level2-input-text" className="error-code-management-input-label">
                      Level 2:
                    </label>
                    <input
                      className="cc-input"
                      type="text"
                      id="level2-input-text"
                      onChange={changeViolationRuleName}
                      value={selectedViolationRule?.name || ''}
                    />
                  </div>
                ) : (
                  selectedViolationRuleRelatives.level2 && (
                    <SelectComponent
                      label="Level 2:"
                      name="level2"
                      onChange={(option: IOption) => setLevelTwoRelative(option)}
                      isDisabled={false}
                      options={childrenAsOptions(selectedViolationRuleRelatives.level1.id)}
                      selectedOption={
                        {
                          value: selectedViolationRuleRelatives.level2.id.toString(),
                          label: selectedViolationRuleRelatives.level2.name,
                        } as IOption
                      }
                      labelDirection="column"
                    />
                  )
                )}
                {selectedViolationRuleRelatives.level3 && (
                  <div className="error-code-management-input-container">
                    <label htmlFor="level3-input-text" className="error-code-management-input-label">
                      Level 3:
                    </label>
                    <input
                      className="cc-input"
                      type="text"
                      id="level3-input-text"
                      onChange={changeViolationRuleName}
                      value={selectedViolationRule?.name || ''}
                    />
                  </div>
                )}
                <div className="error-code-management-input-container">
                  <label htmlFor="error-code-input-text" className="error-code-management-input-label">
                    Error Code:
                  </label>
                  <input
                    className="cc-input"
                    type="text"
                    id="error-code-input-text"
                    onChange={changeViolationRuleErrorCode}
                    disabled={selectedViolationRule?.errorCode ? true : false}
                    value={selectedViolationRule?.errorCode || ''}
                  />
                </div>
                <div className="error-code-management-input-container">
                  <label htmlFor="error-description-input-text" className="error-code-management-input-label">
                    Error description:
                  </label>
                  <input
                    className="cc-input"
                    type="text"
                    id="error-description-input-text"
                    onChange={changeViolationRuleErrorDescription}
                    value={selectedViolationRule?.errorDescription || ''}
                  />
                </div>
                <div className="error-code-management-checkbox-container">
                  <input
                    className="error-code-management-checkbox"
                    type="checkbox"
                    id="error-code-checkbox"
                    onChange={e => setViolationRuleEnabled(e)}
                    checked={selectedViolationRule?.enabled}
                  />
                  <label className="error-code-management-checkbox-label" htmlFor="error-code-checkbox">
                    Enabled
                    <div data-tooltip-id="info-tooltip" data-tooltip-content={AlertMessages.ENABLE__DISABLE_INFO}>
                      <MdInfoOutline size={16} />
                    </div>
                  </label>
                </div>
                <div className="error-code-management-footer">
                  <button onClick={cancel} className="cc-button cc-cancel-button" disabled={false}>
                    Cancel
                  </button>
                  <button onClick={editViolationRule} disabled={false} className="cc-button cc-confirm-button">
                    {false ? (
                      <LoaderComponent styles={{ border: '0.2em solid white', borderTop: '0.2em solid transparent' }} />
                    ) : (
                      'Save'
                    )}
                  </button>
                </div>
              </div>
            ) : isRequesting ? (
              <LoadingScreen styles={{ width: '100%', height: '80vh' }} />
            ) : isCreating ? (
              <div className="error-code-management-form">
                {isCreating.level === 1 && (
                  <div className="error-code-management-input-container">
                    <label htmlFor="level1-creation-input-text" className="error-code-management-input-label">
                      Level 1:
                    </label>
                    <input
                      className="cc-input"
                      type="text"
                      id="level1-creation-input-text"
                      onChange={event => handleNewViolationRuleChange(event, 'name')}
                      value={newViolationRule.name || ''}
                      onBlur={suggestErrorCode}
                    />
                  </div>
                )}
                {isCreating.level !== 1 && (
                  <SelectComponent
                    label="Level 1:"
                    name="level1-creation"
                    onChange={
                      isCreating.level === 2
                        ? (option: IOption) => handleNewViolationRuleChange(null, 'parentId', option)
                        : () => {}
                    }
                    options={level1Options()}
                    isDisabled={isCreating.level === 3}
                    selectedOption={
                      isCreating.level === 3
                        ? ({
                            value: getViolationRule(getViolationRule(isCreating.clickedId!)!.parentId!)?.id.toString(),
                            label: getViolationRule(getViolationRule(isCreating.clickedId!)!.parentId!)?.name,
                          } as IOption)
                        : ({
                            value: newViolationRule.parentId?.toString() || isCreating.clickedId!.toString(),
                            label:
                              getViolationRule(newViolationRule.parentId!)?.name ||
                              getViolationRule(isCreating.clickedId!)?.name,
                          } as IOption)
                    }
                    labelDirection="column"
                  />
                )}
                {isCreating.level === 2 && (
                  <div className="error-code-management-input-container">
                    <label htmlFor="level2-input-text" className="error-code-management-input-label">
                      Level 2:
                    </label>
                    <input
                      className="cc-input"
                      type="text"
                      id="level2-input-text"
                      onChange={event => handleNewViolationRuleChange(event, 'name')}
                      value={newViolationRule.name || ''}
                      onBlur={suggestErrorCode}
                    />
                  </div>
                )}
                {isCreating.level === 3 && (
                  <>
                    <SelectComponent
                      label="Level 2:"
                      name="level2-creation"
                      onChange={(option: IOption) => handleNewViolationRuleChange(null, 'parentId', option)}
                      options={
                        isCreating.level === 3
                          ? childrenAsOptions(getViolationRule(getViolationRule(isCreating.clickedId!)!.parentId!)?.id!)
                          : childrenAsOptions(isCreating.clickedId!)
                      }
                      selectedOption={
                        {
                          value: newViolationRule.parentId?.toString() || isCreating.clickedId!.toString(),
                          label:
                            getViolationRule(newViolationRule.parentId!)?.name ||
                            getViolationRule(isCreating.clickedId!)?.name,
                        } as IOption
                      }
                      labelDirection="column"
                    />
                    <div className="error-code-management-input-container">
                      <label htmlFor="level3-input-text" className="error-code-management-input-label">
                        Level 3:
                      </label>
                      <input
                        className="cc-input"
                        type="text"
                        id="level3-input-text"
                        onChange={event => handleNewViolationRuleChange(event, 'name')}
                        value={newViolationRule.name || ''}
                        onBlur={suggestErrorCode}
                      />
                    </div>
                  </>
                )}
                <div className="error-code-management-input-container">
                  <label htmlFor="error-code-creation-input-text" className="error-code-management-input-label">
                    Error Code:
                  </label>
                  <input
                    className="cc-input"
                    type="text"
                    id="error-code-creation-input-text"
                    onChange={event => handleNewViolationRuleChange(event, 'errorCode')}
                    disabled={false}
                    value={newViolationRule.errorCode || ''}
                  />
                </div>
                <div className="error-code-management-input-container">
                  <label htmlFor="error-description-creation-input-text" className="error-code-management-input-label">
                    Error description:
                  </label>
                  <input
                    className="cc-input"
                    type="text"
                    id="error-description-creation-input-text"
                    onChange={event => handleNewViolationRuleChange(event, 'errorDescription')}
                    value={newViolationRule.errorDescription || ''}
                  />
                </div>
                <div className="error-code-management-checkbox-container">
                  <input
                    className="error-code-management-checkbox"
                    type="checkbox"
                    id="error-code-creation-checkbox"
                    onChange={event => handleNewViolationRuleChange(event, 'enabled')}
                    checked={newViolationRule.enabled}
                  />
                  <label className="error-code-management-checkbox-label" htmlFor="error-code-creation-checkbox">
                    Enabled{' '}
                    <div data-tooltip-id="info-tooltip" data-tooltip-content={AlertMessages.ENABLE__DISABLE_INFO}>
                      <MdInfoOutline size={16} />
                    </div>
                  </label>
                </div>
                <div className="error-code-management-footer">
                  <button onClick={cancel} className="cc-button cc-cancel-button" disabled={false}>
                    Cancel
                  </button>
                  <button onClick={createViolationRule} disabled={false} className="cc-button cc-confirm-button">
                    {false ? (
                      <LoaderComponent styles={{ border: '0.2em solid white', borderTop: '0.2em solid transparent' }} />
                    ) : (
                      'Save'
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Paper>
      </div>
      <Tooltip id="info-tooltip" place="top" />
    </div>
  );
}
