import './index.scss';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
  Line,
  ComposedChart,
} from 'recharts';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import SelectComponent from '../../../components/ui/select-component';
import { IOption } from '../../../components/ui/multi-select-dropdown';
import LoaderComponent from '../../../components/ui/loader';
import reportsService from '../../../resources/reports/reports.service';
import { ChartsDataDTO, DashboardDataDTO, SubmissionDataDTO } from '../../../resources/reports/dashboard-data-dto';
import LoadingScreen from '../../loading';
import { FormState } from '../../../interface/form-state';
import { useAppSelector } from '../../../redux/hooks';
import selectOptions from '../../submission/edit-submission-tab/select-options.json';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { MdInfoOutline } from 'react-icons/md';
import AlertComponent from '../../../components/ui/alert';
import { useFilterOptions } from '../../../hooks/use-filter-options';

interface CardData {
  title: string;
  info?: string;
  subtitle?: string;
  value: number | string;
}

interface DashboardChart<T> {
  title: string;
  data: T[];
}

interface TotalSubmissionsByApChart {
  name: string;
  'Apply Codes': number;
}

interface CompVsEditsChart {
  name: string;
  Reviews: number;
  'Edits Required': number;
}

interface BsmWithIssuesVsReviewsCompleted {
  name: string;
  'BSM with Issues': number;
  'Reviews Completed': number;
  '% of Required Edits': number;
}

const currentDay = moment().format('YYYY-MM-DD');
const firstMonthDay = moment().startOf('month').format('YYYY-MM-DD');

const defaultDashboardFormState = {
  startDate: { value: firstMonthDay, label: firstMonthDay },
  endDate: { value: currentDay, label: currentDay },
  approvedProviders: { value: '', label: '' },
  affiliates: { value: '', label: '' },
  assignees: { value: '', label: '' },
  workflowStatuses: { value: '', label: '' },
  mediaTypes: { value: '', label: '' },
  editsRequired: { value: '', label: 'All' },
  issues: { value: '', label: 'All' },
};

const chartColors = {
  primary: '#2cda9b',
  secondary: '#5282cf',
};

export default function Dashboard() {
  const [dashboardFormState, setDashboardFormState] = useState<FormState>(defaultDashboardFormState);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [cardsData, setCardsData] = useState<CardData[]>();
  const [errorMessage, setErrorMessage] = useState<String>();
  const [submissionsByApChartData, setSubmissionsByApChartData] = useState<DashboardChart<TotalSubmissionsByApChart>>();
  const [compVsEditsChartData, setCompVsEditsChartData] = useState<DashboardChart<CompVsEditsChart>>();
  const [bsmIssuesVsRevCompChartData, setBsmIssuesVsRevCompChartData] =
    useState<DashboardChart<BsmWithIssuesVsReviewsCompleted>>();
  const [yesNoOptions, setYesNoOptions] = useState<IOption[]>();
  const { affiliates, approvedProviders, assignees, workflowStatuses, mediaTypes } = useAppSelector(
    state => state.options,
  );
  const { allAffiliates, allApprovedProviders, allAssignees, allStatus, allMediaTypes } = useFilterOptions({
    affiliates,
    approvedProviders,
    assignees,
    workflowStatuses,
    mediaTypes,
  });
  const filterBody = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!yesNoOptions || yesNoOptions.length === 0) {
      const yesNoOptionsArr = selectOptions['yes-no-options'];
      if (yesNoOptionsArr.at(0)?.label !== 'All') {
        yesNoOptionsArr.unshift({ value: '', label: 'All' });
      }

      setYesNoOptions(yesNoOptionsArr);
    }
  }, []);

  useEffect(() => {
    const shouldFetchData =
      (!cardsData || cardsData.length === 0) &&
      allAffiliates.length > 1 &&
      allApprovedProviders.length > 1 &&
      allAssignees.length > 1 &&
      allStatus.length > 1 &&
      allMediaTypes.length > 0;

    selectAllOptions();

    if (shouldFetchData) {
      fetchDashboardData(allAffiliates, allApprovedProviders, allAssignees, allStatus, allMediaTypes);
    }
  }, [allAffiliates, allApprovedProviders, allAssignees, allStatus, allMediaTypes]);

  function getOptionsValue(options: IOption[]) {
    return options?.map(option => option.value).join(', ');
  }

  function fetchDashboardData(
    affiliates?: IOption[],
    approvedProviders?: IOption[],
    assignees?: IOption[],
    workflowStatuses?: IOption[],
    mediaTypes?: IOption[],
  ) {
    setErrorMessage('');
    const strAffiliates = getOptionsValue(affiliates || []);
    const strApprovedProviders = getOptionsValue(approvedProviders || []);
    const strAssignees = getOptionsValue(assignees || []);
    const strWorkflowStatuses = getOptionsValue(workflowStatuses || []);
    const strMediaTypes = getOptionsValue(mediaTypes || []);

    const processedParams = {
      startDate: dashboardFormState.startDate.value || null,
      endDate: dashboardFormState.endDate.value || null,
      affiliates: strAffiliates ? strAffiliates : dashboardFormState.affiliates.value,
      approvedProviders: strApprovedProviders ? strApprovedProviders : dashboardFormState.approvedProviders.value,
      assignees: strAssignees ? strAssignees : dashboardFormState.assignees.value,
      workflowStatuses: strWorkflowStatuses ? strWorkflowStatuses : dashboardFormState.workflowStatuses.value,
      mediaTypes: strMediaTypes ? strMediaTypes : dashboardFormState.mediaTypes.value,
      editsRequired: dashboardFormState.editsRequired.value ? dashboardFormState.editsRequired.value : null,
      issues: dashboardFormState.issues.value ? dashboardFormState.issues.value : null,
    };

    if (
      processedParams.affiliates.length === 0 ||
      processedParams.approvedProviders.length === 0 ||
      processedParams.assignees.length === 0 ||
      processedParams.workflowStatuses.length === 0 ||
      processedParams.mediaTypes.length === 0
    ) {
      filterBody.current?.scrollTo({ top: 0, behavior: 'smooth' });
      setErrorMessage(
        'Please select at least one option for Affiliate, Approved Provider, Assigned To, Current Status, and BSM Type.',
      );
      return;
    }

    setIsRequesting(true);

    reportsService
      .getDashboardData(
        processedParams.startDate,
        processedParams.endDate,
        processedParams.affiliates,
        processedParams.approvedProviders,
        processedParams.assignees,
        processedParams.workflowStatuses,
        processedParams.mediaTypes,
        processedParams.editsRequired,
        processedParams.issues,
      )
      .promise.then((dashboardDataDTO: DashboardDataDTO) => {
        processCardsData(dashboardDataDTO.submissionData);
        processSubmissionsByApData(dashboardDataDTO.totalSubmissionsByAp);
        processCompVsEditsData(dashboardDataDTO.activeStatusData, dashboardDataDTO.editsRequiredData);
        processReviewedVsEditsData(
          dashboardDataDTO.submissionsWithIssuesData,
          dashboardDataDTO.submissionsReviewCompletedData,
        );
      })
      .finally(() => {
        setIsRequesting(false);
      });
  }

  function processCardsData(submissionData: SubmissionDataDTO) {
    const cardsData = [
      {
        title: 'Apply Codes',
        value: submissionData.applyCodes || 0,
        info: 'Total unique apply codes <br/>associated with submissions.',
      },
      {
        title: 'Submissions',
        value: submissionData.submissions || 0,
        info: 'Total number of submissions',
      },
      {
        title: 'BSM Reviewed',
        value: submissionData.bsmReviewed || 0,
        info: 'Total apply codes that were reviewed.',
      },
    ] as CardData[];

    setCardsData(cardsData);
  }

  function processSubmissionsByApData(submissionsByAp: ChartsDataDTO[]) {
    const totalSubmissionsByApChartData = {
      title: 'BSM Submissions by AP',
      data: [],
    } as DashboardChart<TotalSubmissionsByApChart>;

    submissionsByAp.forEach((chartData: ChartsDataDTO) => {
      totalSubmissionsByApChartData.data.push({
        name: chartData.approvedProviderName,
        'Apply Codes': chartData.totalSubmissions,
      });
    });

    setSubmissionsByApChartData(totalSubmissionsByApChartData);
  }

  function processCompVsEditsData(activeStatusData: ChartsDataDTO[], editsRequiredData: ChartsDataDTO[]) {
    const compVsEditsData = {
      title: 'BSM Completed/In Progress Vs. Edits Required',
      data: [],
    } as DashboardChart<CompVsEditsChart>;

    activeStatusData.forEach((chartData: ChartsDataDTO) => {
      const editsRequiredTotalSubmissions = editsRequiredData.find(
        (editRequired: ChartsDataDTO) => editRequired.approvedProviderId === chartData.approvedProviderId,
      )?.totalSubmissions;

      compVsEditsData.data.push({
        name: chartData.approvedProviderName,
        Reviews: chartData.totalSubmissions,
        'Edits Required': editsRequiredTotalSubmissions ? editsRequiredTotalSubmissions : 0,
      });
    });

    setCompVsEditsChartData(compVsEditsData);
  }

  function processReviewedVsEditsData(
    submissionsWithIssuesData: ChartsDataDTO[],
    submissionsReviewCompletedData: ChartsDataDTO[],
  ) {
    const bsmIssuesVsRevCompData = {
      title: 'BSM w/ Issues Vs. Reviews Completed',
      data: [],
    } as DashboardChart<BsmWithIssuesVsReviewsCompleted>;

    submissionsWithIssuesData.forEach((chartData: ChartsDataDTO) => {
      const editsRequiredTotalSubmissions = submissionsReviewCompletedData.find(
        (editRequired: ChartsDataDTO) => editRequired.approvedProviderId === chartData.approvedProviderId,
      )?.totalSubmissions;
      const reviewsCompleted = editsRequiredTotalSubmissions ? editsRequiredTotalSubmissions : 0;

      bsmIssuesVsRevCompData.data.push({
        name: chartData.approvedProviderName,
        'BSM with Issues': chartData.totalSubmissions,
        'Reviews Completed': reviewsCompleted,
        '% of Required Edits':
          reviewsCompleted === 0 ? 0 : Math.round((chartData.totalSubmissions / reviewsCompleted) * 100),
      });
    });

    setBsmIssuesVsRevCompChartData(bsmIssuesVsRevCompData);
  }

  function handleDateInputs(inputName: string, dateP: string) {
    let date = dateP;

    if (moment(date).isAfter(moment())) {
      date = moment().format('YYYY-MM-DD');
    }

    setDashboardFormState(prevState => ({
      ...prevState,
      [inputName]: {
        value: date,
        label: date,
      },
    }));
  }

  function handleSelectInputChange(inputName: string, option: IOption) {
    setDashboardFormState(prevState => ({
      ...prevState,
      [inputName]: {
        value: option.value,
        label: option.label,
      },
    }));
  }

  function handleMultiSelectInputChange(name: string, options: IOption[]) {
    const values = options.map(option => option.value).join(', ');
    const labels = options.map(option => option.label).join(', ');

    setDashboardFormState(prevState => ({
      ...prevState,
      [name]: {
        value: values,
        label: labels,
      },
    }));
  }

  function resetFormSate() {
    setDashboardFormState(defaultDashboardFormState);
    selectAllOptions();
  }

  function selectAllOptions() {
    handleMultiSelectInputChange('affiliates', allAffiliates);
    handleMultiSelectInputChange('approvedProviders', allApprovedProviders);
    handleMultiSelectInputChange('assignees', allAssignees);
    handleMultiSelectInputChange('workflowStatuses', allStatus);
    handleMultiSelectInputChange('mediaTypes', allMediaTypes);
  }

  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
        <div className="dashboard-content-left-container">
          {isRequesting ? (
            <LoadingScreen styles={{ width: '100%', height: '60vh' }} />
          ) : (
            <div className="dashboard-content-left">
              <div className="dashboard-cards">
                {cardsData?.map((data: CardData, index: number) => (
                  <div className="dashboard-card" key={index}>
                    <div className="dashboard-card-description">
                      <div className="dashboard-card-title">{data.title}</div>
                      {data.subtitle && <div className="dashboard-card-subtitle">{data.subtitle}</div>}
                    </div>
                    <div
                      className="dashboard-card-info"
                      data-tooltip-id="reports-dashboard-tooltip"
                      data-tooltip-html={data.info}>
                      <MdInfoOutline size={16} />
                    </div>
                    <div className="dashboard-card-data">{data.value}</div>
                  </div>
                ))}
              </div>
              {submissionsByApChartData?.data && submissionsByApChartData?.data.length > 0 && (
                <ResponsiveContainer width="100%" height={350}>
                  <BarChart data={submissionsByApChartData?.data} margin={{ left: -16, bottom: 90, top: 25 }}>
                    <text x="50%" y="17" textAnchor="middle" fontSize="14">
                      {submissionsByApChartData?.title}
                    </text>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" interval={0} tick={{ textAnchor: 'end' }} angle={-35} />
                    <YAxis />
                    <Tooltip cursor={false} />
                    <Bar dataKey="Apply Codes" fill={chartColors.primary} barSize={30} />
                  </BarChart>
                </ResponsiveContainer>
              )}
              {compVsEditsChartData?.data && compVsEditsChartData?.data.length > 0 && (
                <ResponsiveContainer width="100%" height={350}>
                  <BarChart data={compVsEditsChartData?.data} margin={{ left: -16, bottom: 90 }}>
                    <text x="40%" y="17" textAnchor="middle" fontSize="14">
                      {compVsEditsChartData?.title}
                    </text>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" interval={0} tick={{ textAnchor: 'end' }} angle={-35} />
                    <YAxis />
                    <Tooltip />
                    <Legend
                      verticalAlign="top"
                      align="right"
                      wrapperStyle={{ fontSize: '14px', paddingBottom: '8px' }}
                    />
                    <Bar dataKey="Reviews" fill={chartColors.primary} barSize={30} />
                    <Bar dataKey="Edits Required" fill={chartColors.secondary} barSize={30} />
                  </BarChart>
                </ResponsiveContainer>
              )}
              {bsmIssuesVsRevCompChartData?.data && bsmIssuesVsRevCompChartData?.data.length > 0 && (
                <ResponsiveContainer width="100%" height={350}>
                  <ComposedChart data={bsmIssuesVsRevCompChartData?.data} margin={{ left: -16, bottom: 90 }}>
                    <text x="25%" y="17" textAnchor="middle" fontSize="14">
                      {bsmIssuesVsRevCompChartData?.title}
                    </text>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" interval={0} tick={{ textAnchor: 'end' }} angle={-35} />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" tickFormatter={value => `${value}%`} />
                    <Tooltip
                      formatter={(value, name) =>
                        name === '% of Required Edits' ? [`${value}%`, name] : [value, name]
                      }
                    />
                    <Legend
                      verticalAlign="top"
                      align="right"
                      wrapperStyle={{ fontSize: '14px', paddingBottom: '8px' }}
                    />
                    <Bar yAxisId="left" dataKey="BSM with Issues" barSize={30} fill={chartColors.primary} />
                    <Bar yAxisId="left" dataKey="Reviews Completed" barSize={30} fill={chartColors.secondary} />
                    <Line
                      yAxisId="right"
                      type="monotone"
                      dataKey="% of Required Edits"
                      stroke="#ff7300"
                      label={({ value, x, y }) => (
                        <text x={x} y={y} dy={value === 0 ? -7 : 15} fontSize={12} textAnchor="middle" fill="#ff7300">
                          {value}%
                        </text>
                      )}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              )}
            </div>
          )}
        </div>
        <div className="dashboard-content-right">
          <div className="dashboard-content-right-header">
            <div className="dashboard-form-title">Filters</div>
            <div
              className="dashboard-form-title-info"
              data-tooltip-id="reports-dashboard-tooltip"
              data-tooltip-html={`<div>
                <p>If the Start Date is empty, only the End Date will be<br/> considered.</p>
                <p>If the End Date is empty, the current date will be used<br/> as the default.</p>
                <p>
                  The "Edits Required" filter retrieves only submissions<br/> that currently have or previously had the
                  status "Edits Requested."
                </p>
                <p>The "Issues" filter retrieves only submissions that<br/> have at least one issue.</p>
              </div>`}>
              <MdInfoOutline size={16} />
            </div>
          </div>
          <div className="dashboard-content-right-body" ref={filterBody}>
            {errorMessage && (
              <AlertComponent text={errorMessage} buttonText="X" onClick={() => setErrorMessage('')} type="danger" />
            )}
            <div className="dashboard-date-inputs">
              <div className="dashboard-input-container">
                <label htmlFor="start-date" className="dashboard-input-label">
                  Start Date:{' '}
                </label>
                <input
                  className="cc-input"
                  type="date"
                  id="start-date"
                  max={moment().format('YYYY-MM-DD')}
                  onChange={e => handleDateInputs('startDate', e.target.value)}
                  value={dashboardFormState.startDate.value}
                />
              </div>
              <div className="dashboard-input-container">
                <label htmlFor="end-date" className="dashboard-input-label">
                  End Date:{' '}
                </label>
                <input
                  className="cc-input"
                  type="date"
                  id="end-date"
                  max={moment().format('YYYY-MM-DD')}
                  onChange={e => handleDateInputs('endDate', e.target.value)}
                  value={dashboardFormState.endDate.value}
                />
              </div>
            </div>
            <SelectComponent
              label="Approved Provider: "
              name="dashboard-approved-provider"
              onChange={(option: IOption[]) => handleMultiSelectInputChange('approvedProviders', option)}
              options={allApprovedProviders}
              selectedOption={
                (dashboardFormState.approvedProviders as IOption).value
                  ? (dashboardFormState.approvedProviders as IOption).value.split(', ').map((val, index) => ({
                      value: val,
                      label: (dashboardFormState.approvedProviders as IOption).label.split(', ')[index],
                    }))
                  : []
              }
              labelDirection="column"
              multiple={true}
              toggleSelection={true}
            />
            <SelectComponent
              label="Affiliate: "
              name="dashboard-affiliate"
              onChange={(option: IOption[]) => handleMultiSelectInputChange('affiliates', option)}
              options={allAffiliates}
              selectedOption={
                (dashboardFormState.affiliates as IOption).value
                  ? (dashboardFormState.affiliates as IOption).value.split(', ').map((val, index) => ({
                      value: val,
                      label: (dashboardFormState.affiliates as IOption).label.split(', ')[index],
                    }))
                  : []
              }
              labelDirection="column"
              multiple={true}
              toggleSelection={true}
            />
            <SelectComponent
              label="BSM Type: "
              name="dashboard-bsm-type"
              onChange={(option: IOption[]) => handleMultiSelectInputChange('mediaTypes', option)}
              options={allMediaTypes}
              selectedOption={
                (dashboardFormState.mediaTypes as IOption).value
                  ? (dashboardFormState.mediaTypes as IOption).value.split(', ').map((val, index) => ({
                      value: val,
                      label: (dashboardFormState.mediaTypes as IOption).label.split(', ')[index],
                    }))
                  : []
              }
              labelDirection="column"
              multiple={true}
              toggleSelection={true}
            />
            <SelectComponent
              label="Assigned To: "
              name="dashboard-assigned-to"
              onChange={(option: IOption[]) => handleMultiSelectInputChange('assignees', option)}
              options={allAssignees}
              selectedOption={
                (dashboardFormState.assignees as IOption).value
                  ? (dashboardFormState.assignees as IOption).value.split(', ').map((val, index) => ({
                      value: val,
                      label: (dashboardFormState.assignees as IOption).label.split(', ')[index],
                    }))
                  : []
              }
              labelDirection="column"
              multiple={true}
              toggleSelection={true}
            />
            <SelectComponent
              label="Current status: "
              name="dashboard-current-status"
              onChange={(option: IOption[]) => handleMultiSelectInputChange('workflowStatuses', option)}
              options={allStatus}
              selectedOption={
                (dashboardFormState.workflowStatuses as IOption).value
                  ? (dashboardFormState.workflowStatuses as IOption).value.split(', ').map((val, index) => ({
                      value: val,
                      label: (dashboardFormState.workflowStatuses as IOption).label.split(', ')[index],
                    }))
                  : []
              }
              labelDirection="column"
              multiple={true}
              toggleSelection={true}
            />
            <SelectComponent
              label="Edits Required: "
              name="dashboard-edited-submissions"
              onChange={(option: IOption) => handleSelectInputChange('editsRequired', option)}
              options={yesNoOptions || []}
              selectedOption={dashboardFormState.editsRequired}
              labelDirection="column"
            />
            <SelectComponent
              label="Issues: "
              name="dashboard-issues"
              onChange={(option: IOption) => handleSelectInputChange('issues', option)}
              options={yesNoOptions || []}
              selectedOption={dashboardFormState.issues}
              labelDirection="column"
            />
          </div>
          <div className="dashboard-buttons">
            <button onClick={resetFormSate} className="cc-button cc-cancel-button" disabled={isRequesting}>
              Reset
            </button>
            <button
              onClick={() => fetchDashboardData()}
              disabled={isRequesting}
              className="cc-button cc-confirm-button">
              {isRequesting ? (
                <LoaderComponent styles={{ border: '0.2em solid white', borderTop: '0.2em solid transparent' }} />
              ) : (
                'Filter'
              )}
            </button>
          </div>
        </div>
      </div>
      <ReactTooltip id="reports-dashboard-tooltip" place="top" />
    </div>
  );
}
